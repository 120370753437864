import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { handleError } from 'src/app/core/common/common';
import {
  EventList,
  TrialOrderHistory,
  TrialOrderShippingAdd,
} from 'src/app/core/model/trial-order.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrialOrdersService {
  baseApiUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }

  getEventList() {
    return this.http
      .get<EventList[]>(`${this.baseApiUrl}/events-orders/trial-order/events`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  getOrderHistory(eventId: string) {
    return this.http
      .get<TrialOrderHistory[]>(
        `${this.baseApiUrl}/events-orders/history?eventId=${eventId}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  getOrderShipping(eventId: string) {
    return this.http
      .get<TrialOrderShippingAdd[]>(
        `${this.baseApiUrl}/events-orders/orders/shipping?eventId=${eventId}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }
}
