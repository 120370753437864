import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EditUserRoleRenderer } from 'src/app/core/grid-renderer/edit-user-role-renderer.component';
import { RefreshComponent } from 'src/app/core/grid-renderer/refresh.component';
import { UserBoxRenderer } from 'src/app/core/grid-renderer/userboxrenderer.component';
import { ManageUser } from 'src/app/core/model/manage-user';
import { ManageUserService } from 'src/app/services/tmna-namc/manage-user.service';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss'],
})
export class ManageUsersComponent implements OnInit {
  columnDefs: ColDef[] = [];
  displayRowData: ManageUser[] = [];
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;

  constructor(
    public router: Router,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly manageUserService: ManageUserService
  ) {}

  ngOnInit(): void {
    this.onLoad();
    this.getcolDefs();
  }

  onLoad() {
    this.ngxUiLoaderService.start('manageuser');
    this.manageUserService.onLoad()?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('manageuser');
        this.displayRowData = result;
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('manageuser');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  getcolDefs() {
    this.columnDefs = [
      {
        headerName: '',
        floatingFilterComponent: RefreshComponent,
        cellRenderer: EditUserRoleRenderer,
        floatingFilterComponentParams: { suppressFilterButton: true },
        minWidth: 40,
        maxWidth: 50,
      },
      {
        headerName: 'USER ID',
        field: 'userId',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 170,
      },
      {
        headerName: 'FIRST NAME',
        field: 'firstName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 170,
      },
      {
        headerName: 'LAST NAME',
        field: 'lastName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 170,
      },
      {
        headerName: 'STATUS',
        field: 'userActiveFlag',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 120,
      },
      {
        headerName: 'NAMC/ROLE',
        field: 'roles',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 530,
        cellRendererFramework: UserBoxRenderer,
      },
    ];
  }

  routeAddUser() {
    this.router?.navigate(['tmna-namc/manage-users/add']);
  }
}
