import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  faFastBackward,
  faFastForward,
  faStepBackward,
  faStepForward,
} from '@fortawesome/free-solid-svg-icons';
import {
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ModelUpdatedEvent,
  SelectionChangedEvent
} from 'ag-grid-community';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit {
  gridApi!: GridApi;
  @Input() context!: { componentParent: any };
  currentPageNo: number | undefined;
  currentPageBoolean = false;
  totalPage = 0;
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  paginationPageSize = 15;
  icons: {} | undefined;
  defaultColDef: {};
  rowHeight = 50;
  gridOptions: GridOptions = { enableCellTextSelection: true,  domLayout: '' as unknown as 'autoHeight' | 'normal' };
  rowClassRules: {};
  @Input() columnDefs: ColDef[] = [];
  @Input() displayRowData: any[] = [];
  @Output() select: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() retainFilter: EventEmitter<ModelUpdatedEvent> =
    new EventEmitter<ModelUpdatedEvent>();
  currentPageRowCount = 0;
  @Input() rowSelection: 'single' | 'multiple' | undefined = undefined;
  lbTotalPages = 0;
  lbCurrentPage = 0;
  faFastForward = faFastForward;
  faStepBackward = faStepBackward;
  faStepForward = faStepForward;
  faFastBackward = faFastBackward;
  gridColumnApi!: ColumnApi;

  constructor() {
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    };
    this.defaultColDef = {
      wrapHeaderText: true,
      autoHeight: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      filterParams: { newRowsAction: 'keep' },
    };
  }

  ngOnInit(): void {
    this.icons = {
      sortUnSort: '<i class="fas fa-sort"></i>',
      sortAscending: '<i class="fas fa-sort-up"></i>',
      sortDescending: '<i class="fas fa-sort-down"></i>',
    };
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridColumnApi = params.columnApi; 
    this.gridApi.addEventListener('filterChanged', () => {
      const filterModel = this.gridApi.getFilterModel(); 
      Object.keys(filterModel).forEach((filterField) => {
        if (filterModel[filterField].filter) {
          filterModel[filterField].filter =
            filterModel[filterField].filter.trim();
        }
      }); 
      this.gridApi.setFilterModel(filterModel);
      this.updatePaginationData();
    });
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    const selected: any[] = [];
    event.api.getSelectedNodes().forEach((value) => {
      selected.push(value.data);
    });
    this.select.emit(selected);
  }

  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }

  onModelUpdated(event: ModelUpdatedEvent): void {
    this.retainFilter.emit(event);
    this.sizeToFit();
  }

  goToNextpage() {
    this.gridApi.paginationGoToNextPage();
  }
  goToPreviousPage() {
    this.gridApi.paginationGoToPreviousPage();
  }
  goToFirstPage() {
    this.gridApi.paginationGoToFirstPage();
  }
  goToLastPage() {
    this.gridApi.paginationGoToLastPage();
  }
  getCurrentPage(): boolean {
    this.currentPageNo = this.gridApi?.paginationGetCurrentPage();

    if (this.currentPageNo === 0) {
      this.currentPageBoolean = true;
    } else {
      this.currentPageBoolean = false;
    }
    return this.currentPageBoolean;
  }
  isLastPage(): boolean {
    this.totalPage = Number(this.gridApi?.paginationGetTotalPages());
    this.currentPageForTotalPage =
      Number(this.gridApi?.paginationGetCurrentPage()) + 1;
    if (this.totalPage === this.currentPageForTotalPage) {
      this.isLastPageFound = true;
    } else {
      this.isLastPageFound = false;
    }
    return this.isLastPageFound;
  }

  onPaginationChanged() {
    if (this.gridApi) {
      this.lbCurrentPage = this.displayRowData.length !== 0 ?
        (this.gridApi.paginationGetCurrentPage() + 1 - 1) *
          this.paginationPageSize +
        1 : 0;
      this.lbTotalPages =
        (this.gridApi.paginationGetCurrentPage() + 1) *
          this.paginationPageSize >
        this.displayRowData.length
          ? this.displayRowData.length
          : (this.gridApi.paginationGetCurrentPage() + 1) *
            this.paginationPageSize;
      this.setLastButtonDisabled(!this.gridApi.paginationIsLastPageFound());
    }
  }

  onPageSizeChanged(event: Event) {
    this.paginationPageSize = Number((event.target as HTMLInputElement).value);
    this.gridApi?.paginationSetPageSize(Number((event.target as HTMLInputElement).value));
  }

  setLastButtonDisabled(_disabled: boolean) {
    this.isLastPageFound = true;
  }

  updatePaginationData() {
    const filteredRowData = [];
    this.gridApi.forEachNodeAfterFilter((node) => {
      filteredRowData.push(node.data);
    });

    this.displayRowData.length = filteredRowData.length;
    this.lbTotalPages = Math.ceil(this.displayRowData.length / this.paginationPageSize);
    this.lbCurrentPage = (this.gridApi.paginationGetCurrentPage() + 1) * this.paginationPageSize > this.displayRowData.length
      ? this.displayRowData.length
      : (this.gridApi.paginationGetCurrentPage() + 1) * this.paginationPageSize;
  }
}
