import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";


@Component({
    selector: 'app-userboxrenderer',
    template: `
     <div>
      <div *ngFor="let item of items" class="cell_border">
        {{ item.namcDesc}}/{{item.roleDesc}}
      </div>&nbsp;&nbsp;
    </div>
    `,
    styles: [`
    .cell_border{
        text-align: center;
        width: 152px;
        height: 28px;
        margin-top: 5px;
        display: inline-block;
        border: 1px solid var(--border-seperator-bdbdbd);
        background: #F5F5F5 0% 0% no-repeat padding-box;
        border: 1px solid #BDBDBD;
        border-radius: 2px;
        opacity: 1; 
    }
    div + div {
        margin: 2px;
    }
    `]
})

export class UserBoxRenderer implements ICellRendererAngularComp {
    items: any[] = [];

    agInit(params: any): void {
        this.items = params.value;
    }

    refresh(): boolean {
        return false;
    }
}