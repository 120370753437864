import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-edituserrolerenderer',
  template: `
    <div
      class="d-flex justify-content-center edit me-3"
      (click)="routeToEditUser()"
    >
      <fa-icon
        [icon]="faEdit"
        title="Edit User"
        (click)="routeToEditUser()"
        (keydown)="routeToEditUser()"
      ></fa-icon>
    </div>
  `,
  styles: [
    `
      .edit {
        width: 26px;
        height: 26px;
        background: transparent
          linear-gradient(180deg, #ffffff 0%, #e4ecf0 100%) 0% 0% no-repeat
          padding-box;
        border: 1px solid #d1d1d1;
        opacity: 1;
      }
    `,
  ],
})
export class EditUserRoleRenderer implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  faEdit = faPencilAlt;

  constructor(public router: Router) {}

  refresh(): boolean {
    return false;
  }

  agInit(
    params: ICellRendererParams){
    this.params = params;
  }

  routeToEditUser() {
    this.router?.navigate(['tmna-namc/manage-users/edit/'], {
      state: { myData: this.params.data.userId },
    });
  }
}
