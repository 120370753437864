<div class = "breadcrumbfake">
  <div>
    <ul class="breadcrumb ps-0">
      <li *ngIf="!hideHome" class="breadcrumb-item ms-4">
        <span routerLink="" class="roboto-medium">Home</span>
      </li>
      <li
        *ngFor="let breadcrumb of breadcrumbs; let i = index"
        class="breadcrumb-item"
        [ngClass]="{ 'breadcrumb-margin': hideHome && i==0 }"
      >
        <fa-icon
          [icon]="faRightAngle"
          style="color: #676a6e; padding-right: 8px"
          *ngIf="(breadcrumbs.length > 0 && i !==0)"
        ></fa-icon>
        <span
          *ngIf="breadcrumbs.length == i + 1"
          class="current roboto-medium"
          >{{ breadcrumb.label }}</span
        >
        <a
          [routerLink]="breadcrumb.url"
          *ngIf="breadcrumbs.length != i + 1 && i!==0"
          class="prev roboto-medium"
          >{{ breadcrumb.label }}</a
        >
        <span
          *ngIf="breadcrumbs.length != i + 1 && i===0"
          class="current roboto-medium"
          >{{ breadcrumb.label }}</span
        >
      </li>
    </ul>
  </div>
  <div *ngIf="requiredDisplayCheck" style="margin-left: auto; text-transform: none;" class="mt-1 breadcrumb-item">
    <span class="required-color required-display px-2"> Required Fields</span>
  </div>
</div>
