<app-breadcrumb></app-breadcrumb>
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<div class="main-container">
  <div class="container">
    <h1 class="heading">SELECT DOCUMENT TO MOVE</h1>
    <div class="content">
      <div class="document-list">
        <ul>
          <li *ngFor="let document of documents; let i = index">
            <input
              type="checkbox"
              [checked]="selectedDocumentIndex === i"
              (change)="onCheckboxChange(i)"
              id="checkbox-{{ i }}"
            />
            <label for="checkbox-{{ i }}">{{ document.documentTitle }}</label>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="buttons">
    <button class="b3" (click)="moveUp()">MOVE UP</button>
    <button class="b3" (click)="moveDown()">MOVE DOWN</button>
  </div>
  <footer class="footer">
    <button class="cancel" (click)="cancel()">CANCEL</button>
    <div class="button-container">
      <button class="b2 submit" (click)="submit()">SUBMIT</button>
    </div>
  </footer>
</div>
