import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ManageAttributesComponent } from 'src/app/pages/tmna-namc/manage-attributes/manage-attributes.component';
import { AttributeList } from '../../model/manage-attributes.model';

@Component({
  selector: 'app-edit-attribute',
  template: `<button *ngIf="params.data?.isEditable === 'Y'" class="me-3">
      <fa-icon
        [icon]="faEdit"
        title="Modify Attribute"
        (click)="modifyAttribute()"
        (keydown)="modifyAttribute()"
      ></fa-icon>
    </button>
    <button *ngIf="params.data?.isEditable === 'Y'">
      <fa-icon
        [icon]="faDelete"
        title="Delete Attribute"
        (click)="deleteAttribute()"
      ></fa-icon>
    </button>`,
  // styleUrls: ['./refresh.component.scss']
})
export class ManageAttributeAction implements ICellRendererAngularComp {
  params!: ICellRendererParams<
    AttributeList,
    undefined,
    { componentParent: ManageAttributesComponent }
  >;
  faEdit = faPencilAlt;
  faDelete = faTrashAlt;
  constructor(private readonly router: Router) {}

  refresh(
    _params: ICellRendererParams<
      AttributeList,
      undefined,
      { componentParent: ManageAttributesComponent }
    >
  ): boolean {
    return false;
  }

  agInit(
    params: ICellRendererParams<
      AttributeList,
      undefined,
      { componentParent: ManageAttributesComponent }
    >
  ): void {
    this.params = params;
  }

  modifyAttribute() {
    const params = {
      attributeID: this.params.data?.attributeID,
      attributeType: this.params.data?.attributeType,
      attributeValue: this.params.data?.attributeValue,
      attributeDescription: this.params.data?.attributeDescription,
    };
    sessionStorage.setItem('modifyAttributeParams', JSON.stringify(params));
    this.router.navigate(['/tmna-namc/manage-attributes/modify-attribute']);
  }
  deleteAttribute() {
    if (this.params.data?.attributeID) {
      this.params?.context.componentParent.deleteAttribute(
        this.params.data.attributeID
      );
    }
  }
}
