<header>
  <div class="close_button" (click)="onNoClick()" (keydown)="onNoClick()">
    x
  </div>
  <div class="header" style="display: block">
    <h1>Delete Document</h1>
  </div>
</header>

<div class="body">
  <div class="catlabel">Are you sure wanted to delete this document/file?</div>
</div>

<div class="footer">
  <button
    (click)="onNoClick()"
    class="cancel"
    style="display: inline; background: transparent; border: none !important"
  >
    CANCEL
  </button>
  <button
    mat-raised-button
    class="submit"
    style="display: inline"
    (click)="saveClick()"
  >
    CONFIRM
  </button>
</div>
