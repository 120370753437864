<app-breadcrumb></app-breadcrumb>
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<form [formGroup]="createForm">
  <div class="main-container pb-3">
    <div class="mb-2 mt-1 fw-bold">Event Detail</div>
    <div class="form-container createEventShadow">
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="namcCode">NAMC CODE</label>
        </div>
        <input
          id="namcCode"
          [ngClass]="{'is-invalid': createForm.get('namcCode')?.touched && createForm.get('namcCode')?.invalid && createForm.get('namcCode')?.errors?.['required']}"
          formControlName="namcCode"
        />
        <div
          *ngIf="
            createForm.get('namcCode')?.touched &&
            createForm.get('namcCode')?.invalid
          "
        >
          <small *ngIf="createForm.get('namcCode')?.errors?.['required']"
            >NAMC CODE is required</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="projectCode">PROJECT CODE</label>
        </div>
        <input
          id="projectCode"
          [ngClass]="{'is-invalid': createForm.get('projectCode')?.touched && createForm.get('projectCode')?.invalid && createForm.get('projectCode')?.errors?.['required']}"
          formControlName="projectCode"
        />
        <div
          *ngIf="
            createForm.get('projectCode')?.touched &&
            createForm.get('projectCode')?.invalid
          "
        >
          <small *ngIf="createForm.get('projectCode')?.errors?.['required']"
            >PROJECT CODE is required</small
          >
          <small *ngIf="createForm.get('projectCode')?.errors?.['maxlength']"
            >PROJECT CODE SHOULD BE UNDER 4 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="partsSource">PARTS SOURCE</label>
        </div>
        <div class="mt-1">
          <p-dropdown
            formControlName="partsSource"
            [options]="partsSourceCatalog"
            optionLabel="attributeDescription"
            optionValue="attributeValue"
            class="dropdownOptions"
            placeholder="(Select)"
            [virtualScroll]="true"
            scrollHeight="100px"
            [ngClass]="{'is-invalid': createForm.get('partsSource')?.touched && createForm.get('partsSource')?.invalid && createForm.get('partsSource')?.errors?.['required']}"
            [virtualScrollItemSize]="10"
          >
          </p-dropdown>
        </div>
        <div
          *ngIf="
            createForm.get('partsSource')?.touched &&
            createForm.get('partsSource')?.invalid
          "
        >
          <small *ngIf="createForm.get('partsSource')?.errors?.['required']"
            >PARTS SOURCE is required</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="eventName">EVENT NAME</label>
        </div>
        <input
          id="eventName"
          [ngClass]="{'is-invalid': createForm.get('eventName')?.touched && createForm.get('eventName')?.invalid && createForm.get('eventName')?.errors?.['required']}"
          formControlName="eventName"
        />
        <div
          *ngIf="
            createForm.get('eventName')?.touched &&
            createForm.get('eventName')?.invalid
          "
        >
          <small *ngIf="createForm.get('eventName')?.errors?.['required']"
            >EVENT NAME is required</small
          >
          <small *ngIf="createForm.get('eventName')?.errors?.['maxlength']"
            >EVENT NAME SHOULD BE UNDER 30 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="eventDescription">EVENT DESCRIPTION</label>
        </div>
        <input
          id="eventDescription"
          [ngClass]="{'is-invalid': createForm.get('eventDescription')?.touched && createForm.get('eventDescription')?.invalid && createForm.get('eventDescription')?.errors?.['required']}"
          formControlName="eventDescription"
        />
        <div
          *ngIf="
            createForm.get('eventDescription')?.touched &&
            createForm.get('eventDescription')?.invalid
          "
        >
          <small
            *ngIf="createForm.get('eventDescription')?.errors?.['required']"
            >EVENT DESCRIPTION is required</small
          >
          <small
            *ngIf="createForm.get('eventDescription')?.errors?.['maxlength']"
            >EVENT DESCRIPTION SHOULD BE UNDER 100 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="assignQpc">ASSIGN QPC TO TRIAL</label>
        </div>
        <div class="mt-1">
          <p-dropdown
            formControlName="assignQpc"
            [options]="assignQpcCatalog"
            optionLabel="description"
            optionValue="value"
            class="dropdownOptions"
            placeholder="(Select)"
            [virtualScroll]="true"
            scrollHeight="70px"
            [ngClass]="{'is-invalid': createForm.get('assignQpc')?.touched && createForm.get('assignQpc')?.invalid && createForm.get('assignQpc')?.errors?.['required']}"
            [virtualScrollItemSize]="10"
          >
          </p-dropdown>
        </div>
        <div
          *ngIf="
            createForm.get('assignQpc')?.touched &&
            createForm.get('assignQpc')?.invalid
          "
        >
          <small *ngIf="createForm.get('assignQpc')?.errors?.['required']"
            >ASSIGN QPC TO TRIAL is required</small
          >
        </div>
      </div>
    </div>
    <div class="mb-2 mt-1 fw-bold">Shipment Details</div>
    <div class="form-container createEventShadow">
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="shipmentLocation">SHIPMENT LOCATION</label>
        </div>
        <input
          id="shipmentLocation"
          [ngClass]="{'is-invalid': createForm.get('shipmentLocation')?.touched && createForm.get('shipmentLocation')?.invalid && createForm.get('shipmentLocation')?.errors?.['required']}"
          formControlName="shipmentLocation"
        />
        <div
          *ngIf="
            createForm.get('shipmentLocation')?.touched &&
            createForm.get('shipmentLocation')?.invalid
          "
        >
          <small
            *ngIf="createForm.get('shipmentLocation')?.errors?.['required']"
            >SHIPMENT LOCATION is required</small
          >
          <small
            *ngIf="createForm.get('shipmentLocation')?.errors?.['maxlength']"
            >SHIPMENT LOCATION SHOULD BE UNDER 30 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="shipmentAddress">SHIPMENT ADDRESS</label>
        </div>
        <input
          id="shipmentAddress"
          [ngClass]="{'is-invalid': createForm.get('shipmentAddress')?.touched && createForm.get('shipmentAddress')?.invalid && createForm.get('shipmentAddress')?.errors?.['required']}"
          formControlName="shipmentAddress"
        />
        <div
          *ngIf="
            createForm.get('shipmentAddress')?.touched &&
            createForm.get('shipmentAddress')?.invalid
          "
        >
          <small *ngIf="createForm.get('shipmentAddress')?.errors?.['required']"
            >SHIPMENT ADDRESS is required</small
          >
          <small *ngIf="createForm.get('shipmentAddress')?.errors?.['maxlength']"
            >SHIPMENT ADDRESS SHOULD BE UNDER 150 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="shipmentCity">SHIPMENT CITY</label>
        </div>
        <input
          id="shipmentCity"
          formControlName="shipmentCity"
          [ngClass]="{'is-invalid': createForm.get('shipmentCity')?.touched && createForm.get('shipmentCity')?.invalid && createForm.get('shipmentCity')?.errors?.['required']}"
        />
        <div
          *ngIf="
            createForm.get('shipmentCity')?.touched &&
            createForm.get('shipmentCity')?.invalid
          "
        >
          <small *ngIf="createForm.get('shipmentCity')?.errors?.['required']"
            >SHIPMENT CITY is required</small
          >
          <small *ngIf="createForm.get('shipmentCity')?.errors?.['maxlength']"
            >SHIPMENT CITY SHOULD BE UNDER 20 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="shipmentState">SHIPMENT STATE/PROVINCE</label>
        </div>
        <input
          id="shipmentState"
          [ngClass]="{'is-invalid': createForm.get('shipmentState')?.touched && createForm.get('shipmentState')?.invalid && createForm.get('shipmentState')?.errors?.['required']}"
          formControlName="shipmentState"
        />
        <div
          *ngIf="
            createForm.get('shipmentState')?.touched &&
            createForm.get('shipmentState')?.invalid
          "
        >
          <small *ngIf="createForm.get('shipmentState')?.errors?.['required']"
            >SHIPMENT STATE/PROVINCE is required</small
          >
          <small *ngIf="createForm.get('shipmentState')?.errors?.['maxlength']"
            >SHIPMENT STATE/PROVINCE should be only 4 charaters</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="shipmentCountry">SHIPMENT COUNTRY</label>
        </div>
        <div class="mt-1">
          <p-dropdown
            formControlName="shipmentCountry"
            [options]="shipmentCountryCatalog"
            optionLabel="attributeDescription"
            optionValue="attributeValue"
            class="dropdownOptions"
            placeholder="(Select)"
            [virtualScroll]="true"
            scrollHeight="120px"
            [ngClass]="{'is-invalid': createForm.get('shipmentCountry')?.touched && createForm.get('shipmentCountry')?.invalid && createForm.get('shipmentCountry')?.errors?.['required']}"
            [virtualScrollItemSize]="10"
          >
          </p-dropdown>
        </div>
        <div
          *ngIf="
            createForm.get('shipmentCountry')?.touched &&
            createForm.get('shipmentCountry')?.invalid
          "
        >
          <small *ngIf="createForm.get('shipmentCountry')?.errors?.['required']"
            >SHIPMENT COUNTRY is required</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="shipmentZip">SHIPMENT ZIP</label>
        </div>
        <input
          id="shipmentZip"
          [ngClass]="{'is-invalid': createForm.get('shipmentZip')?.touched && createForm.get('shipmentZip')?.invalid && createForm.get('shipmentZip')?.errors?.['required']}"
          formControlName="shipmentZip"
        />
        <div
          *ngIf="
            createForm.get('shipmentZip')?.touched &&
            createForm.get('shipmentZip')?.invalid
          "
        >
          <small *ngIf="createForm.get('shipmentZip')?.errors?.['required']"
            >SHIPMENT ZIP is required</small
          >
          <small *ngIf="createForm.get('shipmentZip')?.errors?.['maxlength']"
            >SHIPMENT ZIP SHOULD BE UNDER 20 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <label for="shipmentScheduled">SHIPMENT SCHEDULED TRACKING</label>
        </div>
        <select id="shipmentScheduled" formControlName="shipmentScheduled">
          <option value="" selected>(Select)</option>
          <option value="na">NA</option>
          <option value="Yes">YES</option>
          <option value="No">NO</option>
        </select>
      </div>
    </div>
    <div class="mb-2 mt-1 fw-bold">Order Details</div>
    <div class="form-container createEventShadow">
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="mainRouteNameOverride">MAINROUTE NAME OVERRIDE</label>
        </div>
        <input
          id="mainRouteNameOverride"
          formControlName="mainRouteNameOverride"
        />
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="subRouteNameOverride">SUBROUTE NAME OVERRIDE</label>
        </div>
        <input
          id="subRouteNameOverride"
          formControlName="subRouteNameOverride"
        />
      </div>
      <div class="form-group" *ngIf="createForm.value.namcCode == '71NPE'">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="ringiNumber">RINGI NUMBER</label>
        </div>
        <div class="mt-1">
          <p-dropdown
            formControlName="ringiNumber"
            [options]="ringiNumberCatalog"
            optionLabel="ringiNumber"
            optionValue="ringiNumber"
            class="dropdownOptions"
            placeholder="(Select)"
            [virtualScroll]="true"
            scrollHeight="350px"
            [ngClass]="{'is-invalid': createForm.get('ringiNumber')?.touched && createForm.get('ringiNumber')?.invalid && createForm.get('ringiNumber')?.errors?.['required']}"
            [virtualScrollItemSize]="10"
          >
          </p-dropdown>
        </div>
        <div
          *ngIf="
            createForm.get('ringiNumber')?.touched &&
            createForm.get('ringiNumber')?.invalid
          "
        >
          <small *ngIf="createForm.get('ringiNumber')?.errors?.['required']"
            >RINGI NUMBER is required</small
          >
        </div>
      </div>
      <div class="form-group" *ngIf="createForm.value.namcCode != '71NPE'">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="costCenter">COST CENTRE</label>
        </div>
        <input
          id="costCenter"
          formControlName="costCenter"
          [ngClass]="{'is-invalid': createForm.get('costCenter')?.touched && createForm.get('costCenter')?.invalid && createForm.get('costCenter')?.errors?.['required']}"
        />
        <div
          *ngIf="
            createForm.get('costCenter')?.touched &&
            createForm.get('costCenter')?.invalid
          "
        >
          <small *ngIf="createForm.get('costCenter')?.errors?.['required']"
            >COST CENTRE is required</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="dockCode">DOCK CODE</label>
        </div>
        <input
          id="dockCode"
          formControlName="dockCode"
          [ngClass]="{'is-invalid': createForm.get('dockCode')?.touched && createForm.get('dockCode')?.invalid && createForm.get('dockCode')?.errors?.['required']}"
        />
        <div
          *ngIf="
            createForm.get('dockCode')?.touched &&
            createForm.get('dockCode')?.invalid
          "
        >
          <small *ngIf="createForm.get('dockCode')?.errors?.['required']"
            >DOCK CODE is required</small
          >
          <small *ngIf="createForm.get('dockCode')?.errors?.['maxlength']"
            >DOCK CODE SHOULD BE UNDER 2 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="orderType">ORDER TYPE</label>
        </div>
        <div class="mt-1">
          <p-dropdown
            formControlName="orderType"
            [options]="orderTypeCatalog"
            optionLabel="eventDescription"
            optionValue="eventTypeId"
            class="dropdownOptions"
            placeholder="(Select)"
            [virtualScroll]="true"
            scrollHeight="60px"
            [ngClass]="{'is-invalid': createForm.get('orderType')?.touched && createForm.get('orderType')?.invalid && createForm.get('orderType')?.errors?.['required']}"
            [virtualScrollItemSize]="10"
          >
          </p-dropdown>
        </div>
        <div
          *ngIf="
            createForm.get('orderType')?.touched &&
            createForm.get('orderType')?.invalid
          "
        >
          <small *ngIf="createForm.get('orderType')?.errors?.['required']"
            >ORDER TYPE is required</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="orderNumberSeries">ORDER NUMBER SERIES</label>
        </div>
        <input
          id="orderNumberSeries"
          [ngClass]="{'is-invalid': createForm.get('orderNumberSeries')?.touched && createForm.get('orderNumberSeries')?.invalid && createForm.get('orderNumberSeries')?.errors?.['required']}"
          formControlName="orderNumberSeries"
        />
        <div
          *ngIf="
            createForm.get('orderNumberSeries')?.touched &&
            createForm.get('orderNumberSeries')?.invalid
          "
        >
          <small
            *ngIf="createForm.get('orderNumberSeries')?.errors?.['required']"
            >ORDER NUMBER SERIES is required</small
          >
          <small
            *ngIf="createForm.get('orderNumberSeries')?.errors?.['maxlength']"
            >ORDER NUMBER SERIES SHOULD BE UNDER 6 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="trialOrderSpecialistCode"
            >TRIAL ORDER SPECIALIST CODE</label
          >
        </div>
        <input
          id="trialOrderSpecialistCode"
          [ngClass]="{'is-invalid': createForm.get('trialOrderSpecialistCode')?.touched && createForm.get('trialOrderSpecialistCode')?.invalid && createForm.get('trialOrderSpecialistCode')?.errors?.['required']}"
          formControlName="trialOrderSpecialistCode"
        />
        <div
          *ngIf="
            createForm.get('trialOrderSpecialistCode')?.touched &&
            createForm.get('trialOrderSpecialistCode')?.invalid
          "
        >
          <small
            *ngIf="createForm.get('trialOrderSpecialistCode')?.errors?.['required']"
            >TRIAL ORDER SPECIALIST CODE is required</small
          >
          <small
            *ngIf="createForm.get('trialOrderSpecialistCode')?.errors?.['maxlength']"
            >TRIAL ORDER SPECIALIST CODE should be under 2 digits</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="carryOverParts">CARRY OVER PARTS ORDERING ALLOWED</label>
        </div>
        <div class="mt-2">
          <p-dropdown
            formControlName="carryOverParts"
            [options]="carryOverPartsCatalog"
            optionLabel="description"
            optionValue="value"
            class="dropdownOptions"
            placeholder="(Select)"
            [virtualScroll]="true"
            scrollHeight="70px"
            [ngClass]="{'is-invalid': createForm.get('carryOverParts')?.touched && createForm.get('carryOverParts')?.invalid && createForm.get('carryOverParts')?.errors?.['required']}"
            [virtualScrollItemSize]="10"
          >
          </p-dropdown>
        </div>
        <div
          *ngIf="
            createForm.get('carryOverParts')?.touched &&
            createForm.get('carryOverParts')?.invalid
          "
        >
          <small *ngIf="createForm.get('carryOverParts')?.errors?.['required']"
            >CARRY OVER PARTS ORDERING ALLOWED is required</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <label for="packingNeed">PACKAGING NEED</label>
        </div>
        <div class="mt-3">
          <p-dropdown
            formControlName="packingNeed"
            [options]="packingNeedCatalog"
            optionLabel="attributeDescription"
            optionValue="attributeValue"
            class="dropdownOptions"
            placeholder="(Select)"
            [virtualScroll]="true"
            scrollHeight="70px"
            [virtualScrollItemSize]="10"
          >
          </p-dropdown>
        </div>
        <div
          *ngIf="
            createForm.get('packingNeed')?.touched &&
            createForm.get('packingNeed')?.invalid
          "
        >
          <small *ngIf="createForm.get('packingNeed')?.errors?.['required']"
            >PACKAGING NEED is required</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="rows">ORDER QUANTITY NAMES</label>
        </div>
        <div [formGroup]="createForm">
          <table aria-label="create-event">
            <thead>
              <tr>
                <th>#</th>
                <th>Value</th>
                <th *ngIf="!isDisabled"><button (click)="addRow()" >+</button></th>
              </tr>
            </thead>
            <tbody formArrayName="rows">
              <tr
                *ngFor="let row of rows.controls; let i = index"
                [formGroupName]="i"
              >
                <td>{{ i + 1 }}</td>
                <td>
                  <input formControlName="value" oninput="this.value = this.value.toUpperCase()"/>
                </td>
                <td  *ngIf="!isDisabled">
                  <button *ngIf="i > 3" (click)="deleteRow(i)" >x</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="showErrorMessage">
          <small>You can only Add 5 additional Quantity names </small>
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="logisticsProvider">LOGISTICS PROVIDER</label>
        </div>
        <div class="mt-1">
          <p-dropdown
            formControlName="logisticsProvider"
            [options]="logisticsProviderCatalog"
            optionLabel="attributeDescription"
            optionValue="attributeValue"
            class="dropdownOptions"
            placeholder="(Select)"
            [virtualScroll]="true"
            [ngClass]="{'is-invalid': createForm.get('logisticsProvider')?.touched && createForm.get('logisticsProvider')?.invalid && createForm.get('logisticsProvider')?.errors?.['required']}"
            scrollHeight="200px"
            [virtualScrollItemSize]="10"
          >
          </p-dropdown>
        </div>
        <div
          *ngIf="
            createForm.get('logisticsProvider')?.touched &&
            createForm.get('logisticsProvider')?.invalid
          "
        >
          <small
            *ngIf="createForm.get('logisticsProvider')?.errors?.['required']"
            >LOGISTICS PROVIDER is required</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <label for="recieveOrderNAQDyES">RECEIVE ORDER NAQD MTPROMS </label>
        </div>
        <div class="fakeClass">
          <input
            type="radio"
            name="radioGroup" [disabled]="isDisabled"
            [checked]="selectedOption === 'YES'"
            (click)="toggleSelection('YES')"
            value="YES"
            (keydown)="toggleSelection('YES')"
          />
          <div class="mt-2">YES</div>
          <input
            type="radio"
            name="radioGroup" [disabled]="isDisabled"
            [checked]="selectedOption === 'NO'"
            (click)="toggleSelection('NO')"
            value="NO"
            (keydown)="toggleSelection('NO')"
          />
          <div class="mt-2">NO</div>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer">
    <button class="cancel mt-2" (click)="cancel()">CANCEL</button>

    <div class="button-container">
      <button class="b2 add" *ngIf="!isView" (click)="onSubmit()">SUBMIT</button>
    </div>
  </footer>
</form>
