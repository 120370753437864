import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { handleError } from '../core/common/common';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  baseApiUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }

  login() {
    window.open(`${this.baseApiUrl}/auth/login`, '_self');
  }

  logout() {
    window.open(`${this.baseApiUrl}/auth/logout`, '_self');
  }

  refreshToken() {
    return this.http
      .get(`${this.baseApiUrl}/auth/refresh`, {
        withCredentials: true,
        responseType: 'text',
      })
      .pipe(catchError(handleError));
  }
}
