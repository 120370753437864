import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[disableSpecialCharacters]',
})
export class DisableSpecialCharactersDirective {
  constructor(private readonly el: ElementRef) {}

  @HostListener('keypress', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const allowedCharacters = /^[a-zA-Z0-9, /-]*$/;
    const char = event.key;
    if (!allowedCharacters.test(char)) {
      event.preventDefault();
    }
  }
}
