import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { handleError } from 'src/app/core/common/common';
import {
  LateOrders,
  PieBarLatestatistics,
  SupplierListMap,
} from 'src/app/core/model/performance.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PerformanceService {
  baseApiUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }

  suppliersDropDown() {
    return this.http
      .get<SupplierListMap[]>(`${this.baseApiUrl}/supplier/list`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  pieBarstatistics(supplierCode: string) {
    return this.http
      .get<PieBarLatestatistics>(
        `${this.baseApiUrl}/supplier/performance?suppliercode=${supplierCode}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  lateOrdersParticulars(payload: {
    suppliercode: string;
    month: string;
    year: string;
  }) {
    return this.http
      .post<LateOrders[]>(`${this.baseApiUrl}/supplier/performance`, payload, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }
}
