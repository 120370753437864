import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TrialEventsList } from '../../model/manage-trial-events.model';
import { ManageTrialEventsComponent } from 'src/app/pages/events-and-orders/manage-trial-events/manage-trial-events.component';

@Component({
  selector: 'app-view-event',
  template: `<a href="javascript:;" (click)="openEventView()">{{
    params.value
  }}</a>`,
  // styleUrls: ['./refresh.component.scss']
})
export class ViewEventComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams<
  TrialEventsList,
  undefined,
  { componentParent: ManageTrialEventsComponent }>;

  constructor(private readonly router: Router) {}

  refresh(_params: ICellRendererParams<
    TrialEventsList,
    undefined,
    { componentParent: ManageTrialEventsComponent }>): boolean {
    return false;
  }

  agInit(params: ICellRendererParams<
    TrialEventsList,
    undefined,
    { componentParent: ManageTrialEventsComponent }>): void {
    this.params = params;
  }

  openEventView() {
    this.router.navigate(['/event-order/manage-trial-events/view-event'], {
      queryParams: this.params.data,
    });
  }
}
