import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { handleError } from '../../core/common/common';
import {
  AddNewAttribute,
  AttributeList,
  AttributeType,
  ModifyAttribute,
} from '../../core/model/manage-attributes.model';

@Injectable({
  providedIn: 'root',
})
export class ManageAttributesService {
  baseApiUrl: string;
  message: string | undefined = undefined;
  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }

  getAttributeList() {
    return this.http
      .get<AttributeList[]>(`${this.baseApiUrl}/tmna-namc/attributes`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  getAttributeTypeList() {
    return this.http
      .get<AttributeType[]>(`${this.baseApiUrl}/tmna-namc/attributes/onload`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  addNewAttribute(addAttributeDetails: AddNewAttribute) {
    return this.http
      .post(`${this.baseApiUrl}/tmna-namc/attributes`, addAttributeDetails, {
        withCredentials: true,
        responseType: 'text',
      })
      .pipe(catchError(handleError));
  }

  deleteAttributeService(attributeId: number) {
    return this.http
      .delete(`${this.baseApiUrl}/tmna-namc/attributes?attributeId=${attributeId}`, {
        withCredentials: true,
        responseType: 'text',
      })
      .pipe(catchError(handleError));
  }
  updateAttribute(updateAttributeDetails: ModifyAttribute) {
    return this.http
      .patch(`${this.baseApiUrl}/tmna-namc/attributes`, updateAttributeDetails, {
        withCredentials: true,
        responseType: 'text',
      })
      .pipe(catchError(handleError));
  }

  setMessage(msg?: string) {
    this.message = msg;
  }
  getMessage() {
    return this.message;
  }
}
