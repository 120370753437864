import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-events-and-orders',
  templateUrl: './events-and-orders.component.html',
  styleUrls: ['./events-and-orders.component.scss']
})
export class EventsAndOrdersComponent implements OnInit {

  constructor() {
    // No Work Here
  }

  ngOnInit(): void {
    // No Work Here
  }

}
