import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/common/auth-guard';
import { EventsAndOrdersComponent } from './pages/events-and-orders/events-and-orders.component';
import { CreateEventComponent } from './pages/events-and-orders/manage-trial-events/create-event/create-event.component';
import { ManageTrialEventsComponent } from './pages/events-and-orders/manage-trial-events/manage-trial-events.component';
import { DisplayHistoryComponent } from './pages/events-and-orders/trial-orders/display-history/display-history.component';
import { DisplayOrderShippingAddressComponent } from './pages/events-and-orders/trial-orders/display-order-shipping-address/display-order-shipping-address.component';
import { TrialOrdersComponent } from './pages/events-and-orders/trial-orders/trial-orders.component';
import { HomeComponent } from './pages/home/home.component';
import { NoAccessComponent } from './pages/no-access/no-access.component';
import { RoleSelectorComponent } from './pages/role-selector/role-selector.component';
import { LateOrderGridComponent } from './pages/supplier/performance/late-order-grid/late-order-grid.component';
import { PerformanceComponent } from './pages/supplier/performance/performance.component';
import { AddAttributeComponent } from './pages/tmna-namc/manage-attributes/add-attribute/add-attribute.component';
import { ManageAttributesComponent } from './pages/tmna-namc/manage-attributes/manage-attributes.component';
import { ModifyAttributeComponent } from './pages/tmna-namc/manage-attributes/modify-attribute/modify-attribute.component';
import { ManageConfigurationsComponent } from './pages/tmna-namc/manage-configurations/manage-configurations.component';
import { ModifyConfigurationComponent } from './pages/tmna-namc/manage-configurations/modify-configuration/modify-configuration.component';
import { AddOnlineTrainingComponent } from './pages/tmna-namc/manage-online-training-namc-documents/add-online-training/add-online-training.component';
import { ManageOnlineTrainingComponent } from './pages/tmna-namc/manage-online-training-namc-documents/manage-online-training/manage-online-training.component';
import { ReorderOnlineTrainingComponent } from './pages/tmna-namc/manage-online-training-namc-documents/reorder-online-training/reorder-online-training.component';
import { AddEditUserComponent } from './pages/tmna-namc/manage-users/add-edit-user/add-edit-user.component';
import { ManageUsersComponent } from './pages/tmna-namc/manage-users/manage-users.component';
import { TmnaNamcComponent } from './pages/tmna-namc/tmna-namc.component';
import { ContactsComponent } from './pages/supplier/contacts/contacts.component';

const isAdminRole = ['IS Admin'];
const adminRole = ['TMNA Admin', 'NAMC Admin'];
const allAdminRole = ['TMNA Admin', 'NAMC Admin', 'IS Admin'];
const allNamcRole = ['TMNA Admin', 'NAMC Admin', 'IS Admin', 'PC User', 'PE User', 'QE User', 'Shop User'];

const routes: Routes = [
  {
    path: 'select-roles',
    component: RoleSelectorComponent,
    data: { breadcrumb: 'ROLE SELECTOR' },
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
    data: { breadcrumb: 'NO ACCESS' },
  },
  { path: 'home', component: HomeComponent, data: { breadcrumb: 'HOME' } },
  {
    path: 'performance',
    data: { breadcrumb: 'PERFORMANCE' },
    children: [
      {
        path: '',
        component: PerformanceComponent,
        data: { breadcrumb: null },
      },
      {
        path: 'lateOrders',
        component: LateOrderGridComponent,
        data: { breadcrumb: 'LATE ORDERS' },
      },
    ],
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    data: { breadcrumb: 'CONTACTS' },
  },
  {
    path: 'event-order',
    component: EventsAndOrdersComponent,
    data: { breadcrumb: 'EVENTS & ORDERS' },
    children: [
      {
        path: '',
        redirectTo: '/event-order/manage-trial-events',
        data:{roles: allNamcRole},
        pathMatch: 'full',
      },
      {
        path: 'manage-trial-events',
        data: { breadcrumb: 'MANAGE TRIAL EVENTS',roles: allNamcRole },
        children: [
          {
            path: '',
            component: ManageTrialEventsComponent,
            data: { breadcrumb: '', roles: allNamcRole },
          },
          {
            path: 'create-new-event',
            component: CreateEventComponent,
            data: { breadcrumb: 'CREATE NEW EVENT' },
          },
          {
            path: 'edit-event',
            component: CreateEventComponent,
            data: { breadcrumb: 'EDIT EVENT' },
          },
          {
            path: 'view-event',
            component: CreateEventComponent,
            data: { breadcrumb: 'VIEW EVENT' },
          },
        ],
      },
      {
        path: 'trial-orders',
        data: { breadcrumb: 'TRIAL ORDERS' },
        children: [
          {
            path: '',
            component: TrialOrdersComponent,
            data: { breadcrumb: '' },
          },
          {
            path: 'trialOrderHistory',
            component: DisplayHistoryComponent,
            data: { breadcrumb: 'DISPLAY HISTORY' },
          },
          {
            path: 'shippingAddress',
            component: DisplayOrderShippingAddressComponent,
            data: { breadcrumb: 'VIEW ORDER SHIPPING ADDRESS' },
          },
        ],
      },
    ],
  },
  {
    path: 'tmna-namc',
    component: TmnaNamcComponent,
    data: {
      breadcrumb: 'TMNA/NAMC',
    },
    children: [
      {
        path: 'manage-namc-documents',
        data: {
          breadcrumb: 'MANAGE NAMC DOCUMENTS',
          roles: adminRole,
        },
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ManageOnlineTrainingComponent,
            data: { breadcrumb: null, roles: adminRole },
            canActivate: [AuthGuard],
          },
          {
            path: 're-order',
            component: ReorderOnlineTrainingComponent,
            data: {
              breadcrumb: 'REORDER TRAINING MATERIAL',
              roles: adminRole,
            },
            canActivate: [AuthGuard],
          },
          {
            path: 'add',
            component: AddOnlineTrainingComponent,
            data: {
              breadcrumb: 'ADD TRAINING MATERIAL',
              roles: adminRole,
            },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'manage-online-training',
        data: {
          breadcrumb: 'MANAGE ONLINE TRAINING',
          roles: adminRole,
        },
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ManageOnlineTrainingComponent,
            data: { breadcrumb: null, roles: adminRole },
            canActivate: [AuthGuard],
          },
          {
            path: 're-order',
            component: ReorderOnlineTrainingComponent,
            data: {
              breadcrumb: 'REORDER TRAINING MATERIAL',
              roles: adminRole,
            },
            canActivate: [AuthGuard],
          },
          {
            path: 'add',
            component: AddOnlineTrainingComponent,
            data: {
              breadcrumb: 'ADD TRAINING MATERIAL',
              roles: adminRole,
            },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: '',
        redirectTo: '/tmna-namc/manage-users',
        pathMatch: 'full',
        data:{roles: allAdminRole}
      },
      {
        path: 'manage-users',
        data: { breadcrumb: 'MANAGE USERS' },
        children: [
          {
            path: '',
            component: ManageUsersComponent,
            data: { breadcrumb: '',roles: allAdminRole },
          },
          {
            path: 'edit',
            component: AddEditUserComponent,
            data: { breadcrumb: 'EDIT USER', roles: allAdminRole },
          },
          {
            path: 'add',
            component: AddEditUserComponent,
            data: { breadcrumb: 'ADD USER', roles: allAdminRole },
          },
        ],
      },
      {
        path: 'manage-attributes',
        data: { breadcrumb: 'MANAGE ATTRIBUTES', roles: isAdminRole },
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ManageAttributesComponent,
            data: { breadcrumb: '', roles: isAdminRole },
            canActivate: [AuthGuard],
          },
          {
            path: 'modify-attribute',
            component: ModifyAttributeComponent,
            data: { breadcrumb: 'MODIFY ATTRIBUTE', roles: isAdminRole },
            canActivate: [AuthGuard],
          },
          {
            path: 'add-new-attribute',
            component: AddAttributeComponent,
            data: { breadcrumb: 'ADD NEW ATTRIBUTE', roles: isAdminRole },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'manage-configurations',
        data: { breadcrumb: 'MANAGE CONFIGURATIONS', roles: isAdminRole },
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ManageConfigurationsComponent,
            data: { breadcrumb: '', roles: isAdminRole },
            canActivate: [AuthGuard],
          },
          {
            path: 'modify-configuration',
            component: ModifyConfigurationComponent,
            data: { breadcrumb: 'MODIFY CONFIGURATION', roles: isAdminRole },
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
