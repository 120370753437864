<footer>
  <div
    class="d-flex justify-content-between flex-wrap footer align-items-center"
    id="footer"
  >
    <div class="left">
      <div class="image-cont">
        <img src="assets/images/logo-old@2x.png" alt="logo" />
      </div>
      <span>Copyrighted © 2024</span>
    </div>
    <div class="footer-right">
      <ul>
        <li>
          <a
            href="https://ts.toyotasupplier.com/publicdocs/policies/terms.pdf"
            target="_blank"
            rel="noopener"
            >Terms of Use</a
          >
        </li>
        <li>
          <a
            class="disabledHyperLink"
            href="https://ts.toyotasupplier.com"
            target="_blank"
            rel="noopener"
            >Contact Us</a
          >
        </li>
        <li>
          <a
            href="https://ts.toyotasupplier.com/supplier-guides/privacypolicy.html"
            target="_blank"
            rel="noopener"
            >Privacy Policy</a
          >
        </li>
      </ul>
    </div>
  </div>
</footer>
