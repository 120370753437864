import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FullUserData, UserRoleMapping } from 'src/app/core/model/manage-user';
import { NAMC, NamcRole } from 'src/app/core/model/namc.model';
import { User } from 'src/app/core/model/user.model';
import { AddEditUserService } from 'src/app/services/tmna-namc/add-edit-user.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss'],
})
export class AddEditUserComponent implements OnInit {
  selectedRole: number[] = [];
  isInputDisable = true;
  isSearchBtnDisable = true;
  isSearchBtnClicked = false;
  isStatusChanged = false;
  isAddBtnDisable = false;
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  firstName = '';
  lastName = '';
  contactNumber = '';
  userActiveFlag = '';
  title = '';
  email = '';
  userId = '';
  guid = '';
  namcValue = '';
  spclCodeValue: number[] = [];
  changedSpclCode: string[] = [];
  copyNamcCode: string[] = [];
  roleAtLocation: { [namcCode: string]: number } = {};
  spclCodeAtLocation: { [namcCode: string]: number } = {};
  mappingList: FullUserData | null = null;
  user: User | null = null;
  roles: any[] = [];
  namcRoleList: NamcRole = {
    namcList: [],
    roleList: [],
  };
  options = [
    { value: 'Y', label: 'Active' },
    { value: 'N', label: 'Inactive' },
  ];
  requiredDisplayCheck = true;

  constructor(
    private readonly router: Router,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly addEditUserService: AddEditUserService,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.userDetails.subscribe((value) => {
      if (value !== null) {
        this.user = value;
      }
    });
    this.userService.getUserDetails()?.subscribe((result) => {
      this.roles = result.roles.filter(
        (ele) => ele.roleDescription !== 'Supplier'
      );
      this.getNamcValues();
    });
  }

  onInputChange(index: string) {
    this.changedSpclCode.push(index);
  }

  getNamcValues() {
    this.ngxUiLoaderService.start('namcLoad');
    this.addEditUserService.getNamcList()?.subscribe({
      next: (result: NamcRole) => {
        this.ngxUiLoaderService.stop('namcLoad');
        this.namcRoleList = result;
        this.namcRoleList.roleList = this.namcRoleList.roleList.filter(
          (value) => value.roleDesc !== 'Supplier'
        );
        this.namcRoleList.roleList.push({ roleDesc: '(Select)', roleId: 0 });
        this.namcRoleList.roleList.sort(function (a, b) {
          return a.roleId - b.roleId;
        });
        const namc: string[] = this.roles.map((value) => value.namcCode);
        this.namcRoleList.namcList = this.namcRoleList.namcList.filter(
          (element) => namc.includes(element.code) || element.code === '01ALL'
        );
        this.namcRoleList.namcList.forEach((element) => {
          this.copyNamcCode.push(element.code);
        });
        if (this.router.url === '/tmna-namc/manage-users/add') {
          this.isInputDisable = false;
          this.isSearchBtnDisable = false;
        } else {
          this.userId = String(history.state?.myData);
          this.search('edit');
        }
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('namcLoad');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  search(screen: string) {
    this.isSearchBtnClicked = false;
    this.errorMessage = undefined;
    this.successMessage = undefined;
    if (this.userId === '') {
      this.isSearchBtnClicked = true;
      this.isAddBtnDisable = false;
      return;
    }
    this.ngxUiLoaderService.start('userSearch');
    this.addEditUserService.searchUserDetails(this.userId).subscribe({
      next: (result: FullUserData) => {
        this.ngxUiLoaderService.stop('userSearch');
        this.setFormField(screen, result);
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('userSearch');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  setFormField(screen: string, result: FullUserData) {
    if (screen === 'add') {
      this.firstName = '';
      this.lastName = '';
      this.contactNumber = '';
      this.email = '';
      this.title = '';
      this.guid = '';
      this.userActiveFlag = '';
      if (result.status === 204) {
        this.isAddBtnDisable = true;
        this.firstName = result.data.firstName;
        this.lastName = result.data.lastName;
        this.contactNumber =
          result.data.contactNumber === 'null' ? '' : result.data.contactNumber;
        this.email = result.data.emailId;
        this.title = result.data.title;
        this.guid = result.data.guid;
        this.setMappingDetails(result);
      } else if (result.status === 206) {
        this.isAddBtnDisable = false;
        this.errorMessage = undefined;
        this.errorMessage = 'UserId already exist in system';
      } else {
        this.isAddBtnDisable = false;
        this.errorMessage = undefined;
        this.errorMessage = 'User does not exist in database';
      }
    } else if (screen === 'edit') {
      this.userId = result.data.userId;
      this.firstName = result.data.firstName;
      this.lastName = result.data.lastName;
      this.contactNumber =
        result.data.contactNumber === 'null' ? '' : result.data.contactNumber;
      this.email = result.data.emailId;
      this.title = result.data.title;
      this.userActiveFlag = result.data.userActiveFlag;
      this.guid = result.data.guid;
      this.setMappingDetails(result);
    }
  }

  setMappingDetails(result: FullUserData) {
    this.namcRoleList.namcList = this.namcRoleList.namcList.map(
      (item: NAMC) => {
        return {
          ...item,
          roleId:
            result.data.mappingDetails?.find(
              (role: UserRoleMapping) => role.namcCode === item.code
            )?.roleId ?? null,
          userSpecialCode:
            result.data.mappingDetails?.find(
              (role: UserRoleMapping) => role.namcCode === item.code
            )?.userSpecialCode ?? null,
        };
      }
    );
    this.namcRoleList.namcList.forEach((element: any) => {
      this.selectedRole.push(element?.roleId);
      this.spclCodeValue.push(element?.userSpecialCode ?? null);
    });
  }

  onSubmit() {
    let j = 0;
    this.isStatusChanged = false;
    this.isSearchBtnClicked = false;
    this.errorMessage = undefined;
    this.successMessage = undefined;
    if (this.userId === '') {
      this.isSearchBtnClicked = true;
      return;
    }
    if (this.userActiveFlag === '') {
      this.isStatusChanged = true;
      return;
    }
    if (Object.keys(this.roleAtLocation).length === 0) {
      this.errorMessage = 'Please Select at least 1 NAMC';
      return;
    }
    this.spclCodeValue.forEach((i: number) => {
      if (this.changedSpclCode.includes(this.copyNamcCode[j])) {
        this.spclCodeAtLocation[this.copyNamcCode[j]] = Number(i);
      }
      j++;
    });
    const payload = {
      userId: this.userId,
      firstName: this.firstName,
      lastName: this.lastName,
      title: this.title,
      userStatus: this.userActiveFlag,
      contactNumber: null,
      email: this.email,
      guid: this.guid,
      spclCodeAtLocation: this.spclCodeAtLocation,
      roleAtLocation: this.roleAtLocation,
      loggedInUserId: this.user?.userId,
    };
    this.changedSpclCode = [];
    this.spclCodeAtLocation = {};
    this.roleAtLocation = {};
    this.ngxUiLoaderService.start('submitUserDetails');
    this.addEditUserService.submitUserDetails(payload)?.subscribe({
      next: (_result) => {
        this.ngxUiLoaderService.stop('submitUserDetails');
        this.router.navigateByUrl('/tmna-namc/manage-users');
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('submitUserDetails');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  roleChange(event: any, code: string) {
    this.roleAtLocation[code] = event.value;
  }

  cancel() {
    window.history.back();
  }
}
