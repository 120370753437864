<app-breadcrumb></app-breadcrumb>
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
></app-notification-message>
<div class="export-order">
  <button (click)="exportOrderHistory()">
    <fa-icon [icon]="faShareFromSquare"></fa-icon>
  </button>
</div>
<app-grid
  [columnDefs]="columnDefs"
  [displayRowData]="displayRowData"
></app-grid>
