import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Category } from 'src/app/core/model/manage-online-namc';
import { ManageOnlineTrainingService } from 'src/app/services/tmnaNamc/manage-online-training.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-online-training',
  templateUrl: './add-online-training.component.html',
  styleUrls: ['./add-online-training.component.scss'],
})
export class AddOnlineTrainingComponent implements OnInit {
  form: FormGroup;
  uploadedFileName!: string | null;
  categoryList!: Category[];
  category!: string;
  userId!: string;

  constructor(
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly manageService: ManageOnlineTrainingService,
    private readonly userService: UserService
  ) {
    this.form = this.fb.group({
      title: ['', Validators.required],
      category: ['', Validators.required],
      description: [''],
      file: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.ngxUiLoaderService.start('loader-01');
    this.category = this.router.url.includes('/manage-namc-documents')
      ? 'N'
      : 'S';
    this.userService.userDetails?.subscribe((value) => {
      if (value !== null) {
        this.userId = value.userId;
      }
    });

    this.route?.queryParams?.subscribe((params) => {
      this.form.patchValue({
        title: params['doctitletext'],
        category: params['categoryid'] ? params['categoryid'] : '',
        description: params?.['descriptiontext'],
        file: params['doctitletext'],
      });
    });

    this.manageService
      .categoryDropDown(this.category)
      ?.subscribe((res: Category[]) => {
        this.categoryList = res;
        this.ngxUiLoaderService.stop('loader-01');
      });
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      if (file) {
        this.uploadedFileName = file.name;
        this.form.patchValue({
          file: file,
        });
      }
    }
  }

  removeFile() {
    this.uploadedFileName = null;
    this.form.patchValue({
      file: null,
    });
  }

  get f() {
    return this.form.controls;
  }

  submit() {
    if (this.form.valid) this.addDocument();
    else this.form.markAllAsTouched();
  }

  cancel() {
    window.history.back();
  }

  async addDocument() {
    console.log(this.form.value.file);
    let payload = {
      file: {
        data: await this.fileToBase64(this.form.value.file),
      },
      fileName: this.form.value.file.name,
      fileType: this.form.value.file.name.split('.')[1],
      user: this.userId,
      desc: this.form.value.description ?? null,
      docTitle: this.form.value.title,
      categoryId: this.form.value.category,
      categoryType: this.category,
      fileSize: this.form.value.size,
    };
    this.manageService.addTrainingMaterial(payload).subscribe({});
  }

  fileToBase64(file: File): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = () => {
        const result = reader.result as string;
        resolve(result.split(',')[1]); // Extracts the base64 string only
      };

      reader.readAsDataURL(file);
    });
  }
}
