import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { handleError } from '../core/common/common';
import { SelectedRole } from '../core/model/role-check.model';
import { User } from '../core/model/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly userDetailsSubject: BehaviorSubject<User | null>;
  public userDetails: Observable<User | null>;
  private readonly selectedRolesSubject: BehaviorSubject<SelectedRole | null>;
  public selectedRoles: Observable<SelectedRole | null>;
  baseApiUrl: string;
  cancelBtnValue = false;
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router
  ) {
    this.userDetailsSubject = new BehaviorSubject<User | null>(null);
    this.userDetails = this.userDetailsSubject.asObservable();
    this.selectedRolesSubject = new BehaviorSubject<SelectedRole | null>(null);
    this.selectedRoles = this.selectedRolesSubject.asObservable();
    this.baseApiUrl = environment.baseAPIURL;
  }

  getUserDetails() {
    return this.http
      .get<User>(`${this.baseApiUrl}/user`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  setUserDetails(user: User) {
    this.userDetailsSubject.next(user);
  }

  clearUserDetails() {
    this.userDetailsSubject.next(null);
  }

  checkRoleSelected() {
    try {
      const sessionData: string = sessionStorage.getItem('roleDetails') ?? '';
      if (sessionData === '') {
        throw new Error('Need to Select Roles');
      }
      const selectedRole: SelectedRole = JSON.parse(sessionData);
      this.selectedRolesSubject.next(selectedRole);
    } catch (err) {
      if (
        this.router.url !== '/select-roles' &&
        this.router.url !== '/no-access'
      ) {
        this.router.navigate(['select-roles']);
      }
    }
  }

  clearRoleSelected() {
    this.selectedRolesSubject.next(null);
  }

  public get currentUserValue(): User | null {
    return this.userDetailsSubject.value;
  }

  public get currentSelectedRolesValue(): SelectedRole | null {
    return this.selectedRolesSubject.value;
  }

  setCancelButtonValue() {
    this.cancelBtnValue = true;
  }

  getCancelButtonValue() {
    return this.cancelBtnValue;
  }
}
