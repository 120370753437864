import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { handleError } from '../core/common/common';
import { NAMC } from '../core/model/namc.model';
import { SelectedRole } from '../core/model/role-check.model';
import { Supplier } from '../core/model/supplier.model';

@Injectable({
  providedIn: 'root',
})
export class RoleSelectorService {
  baseApiUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }

  getNamcList() {
    return this.http
      .get<NAMC[]>(`${this.baseApiUrl}/namc`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  getSupplierList() {
    return this.http
      .get<Supplier[]>(`${this.baseApiUrl}/role/supplier`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  getChildSupplierList(supplierCode: string) {
    return this.http
      .get<string[]>(
        `${this.baseApiUrl}/supplier/child-code?mCode=${supplierCode}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  setSelectedRoles(selectedRoles: SelectedRole) {
    return this.http
      .post(`${this.baseApiUrl}/role-selector`, selectedRoles, {
        withCredentials: true,
        responseType: 'text',
      })
      .pipe(catchError(handleError));
  }
}
