import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { handleError } from '../../core/common/common';
import {
  Configuration,
  ConfigurationPayload,
} from '../../core/model/manage-configurations.model';

@Injectable({
  providedIn: 'root',
})
export class ManageConfigurationsService {
  baseApiUrl: string;
  message: string | undefined = undefined;
  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }

  getConfigurationList() {
    return this.http
      .get<Configuration[]>(`${this.baseApiUrl}/tmna-namc/configuration`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  modifyConfigurationList(updateConfigurationDetails: ConfigurationPayload) {
    return this.http
      .patch(
        `${this.baseApiUrl}/tmna-namc/configuration`,
        updateConfigurationDetails,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  setMessage(msg?: string) {
    this.message = msg;
  }
  getMessage() {
    return this.message;
  }
}
