import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'tproms-ui';
  hideFooter = false;

  constructor(private readonly router: Router) {}

  ngOnInit() {
    const e = document.getElementById('footerheight')?.offsetHeight ?? 10;
    const h = document.getElementById('headerHeight')?.offsetHeight ?? 10;
    const t = document.getElementById('toolbarheight')?.offsetHeight ?? 10;
    localStorage.setItem('footerheight', e.toString());
    localStorage.setItem('headerHeight', h.toString());
    localStorage.setItem('toolbarheight', t.toString());
  }
  ngAfterViewInit() {
    const t = document.getElementById('toolbarheight')?.offsetHeight ?? 10;
    localStorage.setItem('toolbarheight', t.toString());
  }
}
