import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-edit-configuration',
  template: `<button *ngIf="params.data?.isEditable === 'Y'">
    <fa-icon
      [icon]="faEdit"
      title="Modify Configuration"
      (click)="modifyConfiguration()"
      (keydown)="modifyConfiguration()"
    ></fa-icon>
  </button>`,
  // styleUrls: ['./refresh.component.scss']
})
export class EditConfigurationComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  faEdit = faPencilAlt;
  constructor(private readonly router: Router) {}

  refresh(_params: ICellRendererParams): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  modifyConfiguration() {
    const params = {
      configId: this.params.data?.configId,
      configKeyName: this.params.data?.configKeyName,
      configKeyValue: this.params.data?.configKeyValue,
      commentsText: this.params.data?.commentsText,
    };
    sessionStorage.setItem('modifyConfigurationParams', JSON.stringify(params));
    this.router.navigate(
      ['/tmna-namc/manage-configurations/modify-configuration']
    );
  }
}
