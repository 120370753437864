import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-comment-renderer',
  template: `
    <div (click)="expandComment(); show = !show">{{ shortenedComment }}</div>
  `,
})
export class CommentRendererComponent implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  comment = '';
  shortenedComment = '';
  show = false;
  trimmedComment = '';

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.comment = params?.value;
    if (
      this.comment !== null &&
      this.comment !== '' &&
      this.comment !== undefined
    ) {
      if (this.comment.length > 30) {
        this.trimmedComment = this.comment.substring(0, 30) + '...';
      } else {
        this.trimmedComment = this.comment;
      }
      this.shortenedComment = this.trimmedComment;
    } else {
      this.trimmedComment = '';
      this.shortenedComment = '';
    }
  }

  refresh(): boolean {
    return false;
  }

  expandComment() {
    // implement logic to expand comment
    if (this.show) {
      this.shortenedComment = this.comment;
    } else {
      this.shortenedComment = this.trimmedComment;
    }
  }
}
