import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ColDef } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DeleteAttributeComponent } from 'src/app/core/dialogs/delete-attribute/delete-attribute.component';
import { ManageAttributeAction } from 'src/app/core/grid-renderer/manage-attribute-action/manage-attribute-action.component';
import { AttributeList } from 'src/app/core/model/manage-attributes.model';
import { ManageAttributesService } from 'src/app/services/tmna-namc/manage-attributes.service';
import { RefreshComponent } from '../../../core/grid-renderer/refresh/refresh.component';
import { CommentRendererComponent } from 'src/app/core/grid-renderer/comment-expansion/comment-renderer';

@Component({
  selector: 'app-manage-attributes',
  templateUrl: './manage-attributes.component.html',
  styleUrls: ['./manage-attributes.component.scss'],
})
export class ManageAttributesComponent implements OnInit {
  faEdit = faPencilAlt;
  faAdd = faPlus;
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  checkEditable = false;
  columnDefs: ColDef<AttributeList>[] = [];
  displayRowData: AttributeList[] = [];
  retainFilter: any;
  context: { componentParent: ManageAttributesComponent };

  constructor(
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly attributeService: ManageAttributesService
  ) {
    this.context = {
      componentParent: this,
    };
  }

  ngOnInit(): void {
    const message = this.attributeService.getMessage();
    if (message) {
      this.successMessage = message;
      this.settingTimeOut();
      this.attributeService.setMessage();
    }
    this.gridDataFetch();
    this.getColDefs();
  }

  getColDefs() {
    this.columnDefs = [
      {
        headerName: 'ATT.ID',
        field: 'attributeID',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) =>
            value.toString().trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 90,
        maxWidth: 90,
      },
      {
        headerName: 'ATTRIBUTES TYPE',
        field: 'attributeType',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 250,
        maxWidth: 250,
        cellRenderer: CommentRendererComponent,
      },
      {
        headerName: 'ATTRIBUTES VALUE',
        field: 'attributeValue',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 270,
        maxWidth: 270,
        cellRenderer: CommentRendererComponent,
      },
      {
        headerName: 'ATTRIBUTES DESCRIPTION',
        field: 'attributeDescription',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
      },
      {
        headerName: 'IS EDITABLE',
        field: 'isEditable',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 110,
        maxWidth: 110,
      },
      {
        headerName: 'ACTION',
        floatingFilterComponent: RefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellRenderer: ManageAttributeAction,
        minWidth: 110,
        maxWidth: 110,
      },
    ];
  }

  addAttribute() {
    this.router.navigate(['/tmna-namc/manage-attributes/add-new-attribute']);
  }

  gridDataFetch() {
    this.ngxUiLoaderService.start('attributeListLoad');
    this.attributeService.getAttributeList().subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('attributeListLoad');
        this.displayRowData = [];
        this.displayRowData = result;
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('attributeListLoad');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error;
        this.settingTimeOut();
      },
    });
  }

  deleteAttribute(attributeID: number) {
    const dialogRef = this.dialog.open(DeleteAttributeComponent, {
      disableClose: true,
      width: '570px',
      height: '185px',
      data: {
        attributeID,
      },
    });

    dialogRef.afterClosed()?.subscribe((result: string | undefined) => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
      if (result === undefined) {
        return;
      }
      if (result === 'Success') {
        this.successMessage = 'Attribute Deleted Successfully';
        this.settingTimeOut();
        this.gridDataFetch();
      }
    });
  }
  settingTimeOut() {
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 5000);
  }
}
