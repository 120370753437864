import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { handleError } from 'src/app/core/common/common';
import { ManageUser } from 'src/app/core/model/manage-user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageUserService {
  
  baseApiUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }

  onLoad() {
    return this.http
      .get<ManageUser[]>(`${this.baseApiUrl}/tmna-namc/user/manage-user`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }
}
