import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { filter } from 'rxjs/operators';
import { Breadcrumb } from '../model/breadcrumb.model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: Breadcrumb[];
  public hideHome = true;
  faRightAngle = faAngleRight;
  @Input()requiredDisplayCheck = false;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.breadcrumbs = this._buildBreadcrumbs(this.activatedRoute.root);
    if (
      this.breadcrumbs.length === 1 &&
      (this.breadcrumbs[0].url === '/select-roles' ||
        this.breadcrumbs[0].url === '/home' ||
        this.breadcrumbs[0].url === '/no-access')
    ) {
      this.hideHome = true;
    }
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.breadcrumbs = this._buildBreadcrumbs(this.activatedRoute.root);
      });
  }

  private _buildBreadcrumbs(
    activatedRoute: ActivatedRoute,
    url = ''
  ): Breadcrumb[] {
    const children: ActivatedRoute[] = activatedRoute.children;

    if (children.length === 0) {
      return [];
    }

    let breadcrumbs: Breadcrumb[] = [];

    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const routeData: Data = child.snapshot.data;
      if (routeData['breadcrumb']) {
        breadcrumbs.push({ label: routeData['breadcrumb'], url: url });
      } else if (routeData['apiData']?.breadcrumb) {
        breadcrumbs.push({
          label: routeData['apiData'].breadcrumb,
          url: url,
        });
      } else if (routeURL !== '') {
        breadcrumbs.push({
          label: routeURL,
          url: url,
        });
      }
      breadcrumbs = breadcrumbs.concat(this._buildBreadcrumbs(child, url));
    }

    return breadcrumbs;
  }
}
