
import { Component } from '@angular/core';
import { IFloatingFilterAngularComp } from 'ag-grid-angular';
import { FilterChangedEvent, IFloatingFilterParams } from 'ag-grid-community';

@Component({
  selector: 'app-refresh',
  template: `<img (click)="reload()" src="assets/images/refresh.svg" style="cursor:pointer;">`,
})
export class RefreshComponent implements IFloatingFilterAngularComp {
  private params!: IFloatingFilterParams;

  constructor() {
    //No work
  }
  onParentModelChanged(_parentModel: any, _filterChangedEvent?: FilterChangedEvent): void {
  }
  afterGuiAttached?(): void {
    //Nothing to implement as of now
  }

  agInit(params: IFloatingFilterParams): void {
    this.params = params;
  }

  reload(){
    this.params.api.setFilterModel(null);
  }


}