import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-document',
  templateUrl: './delete-document.component.html',
  styleUrls: ['./delete-document.component.scss'],
})
export class DeleteDocumentComponent{
  constructor(public dialogRef: MatDialogRef<DeleteDocumentComponent>) {}

  onNoClick(): void {
    this.dialogRef.close({
      event: 'NoEvent',
    });
  }

  public saveClick() {
    this.dialogRef.close({
      event: 'ConfirmEvent',
    });
  }
}
