<app-breadcrumb [requiredDisplayCheck]="requiredDisplayCheck"></app-breadcrumb>
<div class="d-flex align-items-center mx-4 justify-content-between mt-3">
  <span class="header-display">
    Please Select {{ selectText }} For This Session</span
  >
</div>

<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>

<form [formGroup]="loginForm">
  <div *ngIf="userRole === undefined">
    <hr />
    <div class="d-flex align-items-center mx-4 justify-content-between">
      <span class="required-color header-display px-2"> ROLE</span>
    </div>
    <div class="d-flex align-items-center mx-4 my-2 justify-content-between">
      <mat-radio-group
        aria-labelledby="example-radio-group-label"
        class="example-radio-group d-flex gap-2"
        formControlName="selectedValue"
      >
        <mat-radio-button
          class="example-radio-button required-display"
          [value]="option.value"
          [ngClass]="{
            'bold-label':
              loginForm.controls['selectedValue'].value === option.value
          }"
          *ngFor="let option of options"
          (change)="onRoleSelect($event)"
        >
          {{ option.label }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div
      class="d-flex align-items-center mx-4 my-2 justify-content-between"
      *ngIf="
        loginForm.controls['selectedValue'].invalid &&
        (loginForm.controls['selectedValue'].dirty ||
          loginForm.controls['selectedValue'].touched)
      "
    >
      <small
        class="error col-4"
        *ngIf="loginForm.controls['selectedValue'].hasError('required')"
      >
        Please Select a Role
      </small>
    </div>
  </div>
  <hr />
  <div *ngIf="roleSetNamc">
    <div class="d-flex align-items-center mx-4 col-4 justify-content-between">
      <span class="required-color header-display px-2"> NAMC</span>
    </div>
    <div
      class="d-flex align-items-center mx-4 col-4 my-2 justify-content-between"
    >
      <p-dropdown
        [options]="namcList"
        formControlName="namcValue"
        optionLabel="label"
        optionValue="code"
        class="dropdownOptions"
        placeholder="Select a NAMC"
        styleClass="w-50"
      >
      </p-dropdown>
    </div>
    <div
      class="d-flex align-items-center mx-4 col-4 my-2 justify-content-between"
      *ngIf="
        roleSetNamc &&
        this.loginForm.controls['namcValue'].invalid &&
        (this.loginForm.controls['namcValue'].dirty ||
          this.loginForm.controls['namcValue'].touched)
      "
    >
      <small
        class="error col-4"
        *ngIf="this.loginForm.controls['namcValue'].hasError('required')"
      >
        Please Select a NAMC
      </small>
    </div>
  </div>
  <div *ngIf="roleSetSupplier" class="d-flex">
    <div class="d-flex mx-4 col-4" style="flex-direction: column">
      <span class="required-color header-display px-2 mb-2">
        Supplier Code</span
      >
      <p-dropdown
        [options]="supplierList"
        formControlName="supplierValue"
        optionLabel="label"
        optionValue="code"
        class="dropdownOptions"
        placeholder="Select a Supplier Code"
        styleClass="w-50"
        (onChange)="onSupplierChange()"
      >
      </p-dropdown>
      <small
        class="error col-4"
        *ngIf="
          this.loginForm.controls['supplierValue'].invalid &&
          (this.loginForm.controls['supplierValue'].dirty ||
            this.loginForm.controls['supplierValue'].touched) &&
          this.loginForm.controls['supplierValue'].hasError('required')
        "
      >
        Please Select a Supplier Code
      </small>
    </div>

    <div class="d-flex mx-4 col-4" style="flex-direction: column">
      <span class="required-color header-display px-2 mb-2">
        Child Supplier Code</span
      >
      <p-dropdown
        [options]="cSupplierList"
        #dropDownThing
        formControlName="childSupplierValue"
        optionLabel="label"
        optionValue="code"
        optionValue="code"
        class="dropdownOptions me-4"
        placeholder="Select a child Supplier Code"
        styleClass="w-50"
      >
      </p-dropdown>
      <small
        class="error col-4 me-4"
        *ngIf="
          this.loginForm.controls['childSupplierValue'].invalid &&
          (this.loginForm.controls['childSupplierValue'].dirty ||
            this.loginForm.controls['childSupplierValue'].touched) &&
          this.loginForm.controls['childSupplierValue'].hasError('required')
        "
      >
        Please Select a Child Supplier Code
      </small>
    </div>
  </div>
  <div class="create_footer fixed-bottom">
    <button
      class="cancel cancelalign me-3 mt-2"
      *ngIf="cancelValue"
      (click)="cancel()"
    >
      CANCEL
    </button>
    <button
      class="black-button blackbtnalign me-3 mt-2"
      [disabled]="loginForm.invalid"
      (click)="onSubmit()"
    >
      SUBMIT
    </button>
  </div>
</form>
