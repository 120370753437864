<app-breadcrumb></app-breadcrumb>
<div class="form-container">
  <form [formGroup]="form">
    <div class="form-group">
      <div class="label-container">
        <div class="vertical-bar"></div>
        <label for="title">TITLE</label>
      </div>
      <input
        id="title"
        formControlName="title"
        type="text"
        [ngClass]="{'is-invalid': form.get('title')?.touched && form.get('title')?.invalid && form.get('title')?.errors?.['required']}"
      />
      <div *ngIf="form.get('title')?.touched && form.get('title')?.invalid">
        <small
          *ngIf="form.get('title')?.errors?.['required']"
          class="errorValid"
          >Title is required.</small
        >
      </div>
    </div>

    <div class="form-group">
      <div class="label-container">
        <div class="vertical-bar"></div>
        <label for="category">CATEGORY</label>
      </div>
      <select
        id="category"
        formControlName="category"
        [ngClass]="{'is-invalid': form.get('category')?.touched && form.get('category')?.invalid && form.get('category')?.errors?.['required']}"
      >
        <option value="" style="font-size: 13px; top: -8px">
          Select Category
        </option>
        <option
          *ngFor="let category of categoryList"
          [value]="category.categoryid"
        >
          {{ category.categoryname }}
        </option>
      </select>
      <div
        *ngIf="form.get('category')?.touched && form.get('category')?.invalid"
      >
        <small
          *ngIf="form.get('category')?.errors?.['required']"
          class="errorValid"
          >Category is required.</small
        >
      </div>
    </div>

    <div class="form-group">
      <div class="label-container">
        <label for="description">DESCRIPTION</label>
      </div>
      <textarea
        id="description"
        style="height: 66px"
        formControlName="description"
        rows="4"
      ></textarea>
    </div>

    <div class="form-group">
      <div class="label-container">
        <div class="vertical-bar"></div>
        <label for="file">FILE</label>
      </div>
      <input id="file" type="file" (change)="onFileChange($event)" hidden />
      <button
        type="button"
        class="custom-file-upload"
        (click)="fileInput.click()"
      >
        Choose File
      </button>
      <input
        type="file"
        #fileInput
        (change)="onFileChange($event)"
        style="display: none"
      />
      <div *ngIf="uploadedFileName" class="file-info">
        <span>{{ uploadedFileName }}</span>
        <div class="btn" (click)="removeFile()" (keydown)="removeFile()">&#x2212;</div>
      </div>
      <div *ngIf="form.get('file')?.touched && form.get('file')?.invalid">
        <small *ngIf="form.get('file')?.errors?.['required']" class="errorValid"
          >File is required.</small
        >
      </div>
    </div>
    <footer class="footer">
      <button class="cancel" (click)="cancel()">CANCEL</button>

      <div class="button-container">
        <button class="b2 add" (click)="submit()">UPLOAD & SAVE</button>
      </div>
    </footer>
  </form>
</div>
