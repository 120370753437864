import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ManageAttributesService } from 'src/app/services/tmna-namc/manage-attributes.service';

@Component({
  selector: 'app-delete-attribute',
  templateUrl: './delete-attribute.component.html',
  styleUrls: ['./delete-attribute.component.scss'],
})
export class DeleteAttributeComponent implements OnInit {
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  constructor(
    public dialogRef: MatDialogRef<DeleteAttributeComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: { attributeID: number },
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly attributeService: ManageAttributesService
  ) {}

  ngOnInit(): void {
    //no work
  }

  closeDialog() {
    this.dialogRef.close();
  }

  deleteAttribute() {
    //Now work
    this.ngxUiLoaderService.start('deleteAttribute');
    this.attributeService
      .deleteAttributeService(this.dataStore.attributeID)
      .subscribe({
        next: (_result) => {
          this.ngxUiLoaderService.stop('deleteAttribute');
          this.dialogRef.close('Success');
        },
        error: (err) => {
          this.ngxUiLoaderService.stop('deleteAttribute');
          const error = err as HttpErrorResponse;
          this.errorMessage = error.error;
          this.settingTimeOut();
        },
      });
  }
  settingTimeOut() {
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 5000);
  }
}
