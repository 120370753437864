<nav class="navbar navbar-expand-md navbar-light ps-4">
  <div class="heading-class">
    <img
      src="./assets/images/toyota-logo.png"
      class="nlogo"
      alt="Toyota Logo"
    />
  </div>
  <div class="title_taskmanager">
    <span>TPROMS</span>
  </div>
  <div class="carousel-navbar col-7 d-flex align-items-center">
    <div class="carousel-wrapper" #carouselWrapper>
      <ul class="menu-items" style="transition: all 0.2s ease">
        <li class="home" routerLinkActive="active">
          <div class="d-flex align-items-center" mat-button>
            <mat-icon class="home-icon" routerLink="/home">home</mat-icon>
          </div>
        </li>
        <span class="divider" *ngIf="enableMenu"></span>
        <li routerLinkActive="active" *ngIf="enableMenu">
          <div
            class="d-flex align-items-center event-order"
            mat-button
            [matMenuTriggerFor]="belowMenu"
          >
            EVENTS & ORDERS
            <mat-icon>expand_more</mat-icon>
            <mat-menu #belowMenu="matMenu" yPosition="below">
              <div
                class="links"
                routerLink="/event-order/manage-trial-events"
                *ngIf="currentRole !== 'Supplier'"
              >
                MANAGE TRIAL EVENTS
              </div>
              <div class="links" routerLink="/event-order/trial-orders">
                TRIAL ORDERS
              </div>
              <div
                class="links"
                routerLink="qualityControl"
                *ngIf="currentRole === 'Supplier'"
              >
                NAMC DOCUMENTS
              </div>
              <div
                class="links"
                routerLink="qualityControl"
                *ngIf="
                  currentRole === 'TMNA Admin' || currentRole === 'NAMC Admin'
                "
              >
                UPLOAD ORDERS
              </div>
              <div
                class="links"
                routerLink="qualityControl"
                *ngIf="
                  currentRole === 'TMNA Admin' || currentRole === 'NAMC Admin'
                "
              >
                UPLOAD UPDATED ORDERS
              </div>
              <div class="links" routerLink="qualityControl">
                ARCHIVED ORDERS DATA
              </div>
              <div
                class="links"
                routerLink="qualityControl"
                *ngIf="currentRole === 'Supplier'"
              >
                UPLOAD ECI DATA
              </div>
              <div
                class="links"
                routerLink="qualityControl"
                *ngIf="currentRole === 'Supplier'"
              >
                UPLOAD LABEL REQUEST
              </div>
              <div
                class="links"
                routerLink="qualityControl"
                *ngIf="currentRole === 'Supplier'"
              >
                UPLOAD SHIPPING INFO
              </div>
            </mat-menu>
          </div>
        </li>
        <span
          class="divider"
          *ngIf="enableMenu && currentRole !== 'Supplier'"
        ></span>
        <li
          routerLinkActive="active"
          *ngIf="enableMenu && currentRole !== 'Supplier'"
        >
          <div
            class="d-flex align-items-center supplier-menu"
            mat-button
            [matMenuTriggerFor]="belowMenu2"
          >
            SUPPLIERS<mat-icon>expand_more</mat-icon>
            <mat-menu #belowMenu2="matMenu" yPosition="below">
              <div
                class="links"
                routerLink="/performance"
                *ngIf="
                  currentRole === 'IS Admin' ||
                  currentRole === 'TMNA Admin' ||
                  currentRole === 'NAMC Admin'
                "
              >
                PERFORMANCE
              </div>
              <div class="links" routerLink="/contacts">CONTACTS</div>
              <div class="links" routerLink="/ringi-list">ONLINE TRAINING</div>
              <div class="links" routerLink="/ringi-list">NAMC DOCUMENTS</div>
              <div
                class="links"
                routerLink="/ringi-list"
                *ngIf="
                  currentRole === 'IS Admin' ||
                  currentRole === 'TMNA Admin' ||
                  currentRole === 'NAMC Admin'
                "
              >
                MASS EMAIL
              </div>
            </mat-menu>
          </div>
        </li>
        <span
          class="divider"
          *ngIf="
            enableMenu &&
            (currentRole === 'IS Admin' ||
              currentRole === 'TMNA Admin' ||
              currentRole === 'NAMC Admin')
          "
        ></span>
        <li
          routerLinkActive="active"
          *ngIf="
            enableMenu &&
            (currentRole === 'IS Admin' ||
              currentRole === 'TMNA Admin' ||
              currentRole === 'NAMC Admin')
          "
        >
          <div
            class="d-flex align-items-center tmna-menu"
            mat-button
            [matMenuTriggerFor]="belowMenu3"
          >
            TMNA/NAMC<mat-icon>expand_more</mat-icon>
            <mat-menu #belowMenu3="matMenu" yPosition="below">
              <div class="links" routerLink="/tmna-namc/manage-users">
                MANAGE USER
              </div>
              <div
                class="links"
                routerLink="/tmna-namc/manage-online-training"
                *ngIf="
                  currentRole === 'TMNA Admin' || currentRole === 'NAMC Admin'
                "
              >
                MANAGE ONLINE TRAINING
              </div>
              <div
                class="links"
                routerLink="/tmna-namc/manage-namc-documents"
                *ngIf="
                  currentRole === 'TMNA Admin' || currentRole === 'NAMC Admin'
                "
              >
                MANAGE NAMC DOCUMENTS
              </div>
              <div
                class="links"
                routerLink="/tmna-namc/manage-attributes"
                *ngIf="currentRole === 'IS Admin'"
              >
                MANAGE ATTRIBUTES
              </div>
              <div
                class="links"
                routerLink="/tmna-namc/manage-configurations"
                *ngIf="currentRole === 'IS Admin'"
              >
                MANAGE CONFIGURATIONS
              </div>
            </mat-menu>
          </div>
        </li>
        <span
          class="divider"
          *ngIf="enableMenu && currentRole === 'Supplier'"
        ></span>
        <li
          routerLinkActive="active"
          *ngIf="enableMenu && currentRole === 'Supplier'"
        >
          <div
            class="d-flex align-items-center"
            mat-button
            [matMenuTriggerFor]="belowMenu4"
          >
            REFERENCES<mat-icon>expand_more</mat-icon>
            <mat-menu #belowMenu4="matMenu" yPosition="below">
              <div class="links" routerLink="/performance">PERFORMANCE</div>
            </mat-menu>
          </div>
        </li>
        <span
          class="divider"
          *ngIf="enableMenu && currentRole === 'Supplier'"
        ></span>
        <li
          routerLinkActive="active"
          *ngIf="enableMenu && currentRole === 'Supplier'"
        >
          <div
            class="d-flex align-items-center"
            mat-button
            [matMenuTriggerFor]="belowMenu5"
          >
            HELP<mat-icon>expand_more</mat-icon>
            <mat-menu #belowMenu5="matMenu" yPosition="below">
              <div class="links" routerLink="/routingtest">ONLINE TRAINING</div>
            </mat-menu>
          </div>
        </li>
        <span class="divider"></span>
      </ul>
    </div>
  </div>

  <div class="user" *ngIf="user !== null">
    <div class="name_role">
      <span
        class="d-flex align-items-center"
        mat-button
        [matMenuTriggerFor]="belowMenu8"
        >{{ user.userId.toUpperCase() }}
        <mat-icon class="mx-2 drop-ico">expand_more</mat-icon>
        <mat-menu #belowMenu8="matMenu" xPosition="before">
          <div mat-menu-item [disabled]="true" class="user-links adjust-height" >
            <mat-icon style="color: #000000de; opacity: 1">person</mat-icon>
            <span  style="color: #000000de; opacity: 1" class="roboto-bold"
              >{{ user.userId.toUpperCase() }}</span>
              <p class="roboto-bold adjust-item">{{ currentRole ?   currentRole : "" }}</p
            >
          </div>
          <div
            mat-menu-item
            (click)="changeRole()"
            (keydown)="changeRole()"
            *ngIf="changeRoleText !== ''"
            class="user-links"
          >
            <mat-icon>
              <fa-icon [icon]="faRefresh"></fa-icon>
            </mat-icon>
            <span class="roboto-bold">{{ changeRoleText }}</span>
          </div>
          <div
            mat-menu-item
            (click)="logout()"
            (keydown)="logout()"
            class="user-links"
          >
            <mat-icon>
              <fa-icon [icon]="faLogout"></fa-icon>
            </mat-icon>
            <span class="roboto-bold">LOGOUT</span>
          </div>
        </mat-menu>
      </span>
      <div>
        <span style="color: #cc0000; font-weight: bold"
          >{{ currentRole
          }}{{ roleSubject ? " (" + roleSubject + ")" : "" }}</span
        >
      </div>
    </div>
  </div>
</nav>
