import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tmna-namc',
  templateUrl: './tmna-namc.component.html',
  styleUrls: ['./tmna-namc.component.scss'],
})
export class TmnaNamcComponent implements OnInit {
  constructor() {
    //no work
  }

  ngOnInit(): void {
    //no work
  }
}
