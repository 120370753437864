<app-breadcrumb></app-breadcrumb>
<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<div
  class="addeditimage d-flex align-items-center justify-content-end mt-2 me-2"
>
  <button class="me-3" style="height: 26px">
    <fa-icon
      [icon]="faAdd"
      title="Add Attribute"
      (click)="addAttribute()"
      (keydown)="addAttribute()"
    ></fa-icon>
  </button>
</div>
<app-grid
  [columnDefs]="columnDefs"
  [displayRowData]="displayRowData"
  [context]="context"
></app-grid>
