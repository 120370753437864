import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { SelectedRole } from '../model/role-check.model';
import { User } from '../model/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  user: User | null = null;
  selectedRoles: SelectedRole | null = null;
  currentRole = '';
  roleSubject = '';
  hasAccess = false;

  constructor(private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    this.currentRole = sessionStorage.getItem('roleName') ?? '';
    if (route.data['roles'].includes(this.currentRole)) {
      return true;
    } else {
      this.router.navigate(['/no-access']);
      return false;
    }
  }
}
