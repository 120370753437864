import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { WarningPopupComponent } from 'src/app/core/dialogs/warning-popup/warning-popup.component';
import { User } from 'src/app/core/model/user.model';
import { ManageAttributesService } from 'src/app/services/tmna-namc/manage-attributes.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-modify-attribute',
  templateUrl: './modify-attribute.component.html',
  styleUrls: ['./modify-attribute.component.scss'],
})
export class ModifyAttributeComponent implements OnInit {
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  attId!: number;
  attType = '';
  userId = '';
  user: User | null = null;
  public modifyAttributeForm: FormGroup = {} as FormGroup;
  requiredDisplayCheck = true;

  constructor(
    private readonly router: Router,
    public formbuilder: FormBuilder,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly attributeService: ManageAttributesService,
    private readonly userService: UserService,
    private readonly dialog: MatDialog,
  ) {
    this.modifyAttributeForm = this.formbuilder.group({
      attValue: ['', Validators.required],
      attDesc: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    const paramData = JSON.parse(sessionStorage.getItem("modifyAttributeParams") ?? '{}');
    if (Object.keys(paramData).length !== 0) {
      this.attId = paramData.attributeID;
      this.attType = paramData.attributeType;
      this.modifyAttributeForm.patchValue({
        attValue: paramData.attributeValue,
        attDesc: paramData.attributeDescription,
      });
    }
    else{
      this.errorMessage = "Please select an attribute from Manage Attributes page.";
      this.settingTimeOut();
    }

    this.userService.userDetails.subscribe((value) => {
      if (value !== null) {
        this.user = value;
        this.userId = this.user.userId;
      }
    });
  }

  cancel() {
    const dialogRef = this.dialog.open(WarningPopupComponent, {
      disableClose: true,
      width: '570px',
      height: '223px',
    });

    dialogRef.afterClosed()?.subscribe((result: string | undefined) => {
      if (result === undefined) {
        return;
      }
      if (result === 'Success') {
        this.router.navigate(['/tmna-namc/manage-attributes']);
      }
    });
  }

  updateAttributePayload() {
    return {
      attributeId: this.attId,
      attributeValue: this.modifyAttributeForm.controls['attValue'].value,
      attributeDescription: this.modifyAttributeForm.controls['attDesc'].value,
      userId: this.userId,
    };
  }
  updateAttribute() {
    this.modifyAttributeForm.markAllAsTouched();
    const modifyAttributeData = this.updateAttributePayload();
    this.ngxUiLoaderService.start('updateAttribute');
    this.attributeService.updateAttribute(modifyAttributeData).subscribe({
      next: (_result) => {
        this.ngxUiLoaderService.stop('updateAttribute');
        this.attributeService.setMessage('Attribute Edited Successfully.');
        this.router.navigate(['/tmna-namc/manage-attributes']);
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('updateAttribute');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error;
        this.settingTimeOut();
      },
    });
  }

  settingTimeOut() {
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 5000);
  }
}
