import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AgGridModule } from 'ag-grid-angular';
import { NgChartsModule } from 'ng2-charts';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BreadcrumbComponent } from './core/breadcrumb/breadcrumb.component';
import { ngxUiLoaderConfig } from './core/constant/spinner.constant';
import { DeleteEventComponent } from './core/dialogs/delete-event/delete-event.component';
import { DeleteAttributeComponent } from './core/dialogs/delete-attribute/delete-attribute.component';
import { FooterComponent } from './core/footer/footer.component';
import { EditConfigurationComponent } from './core/grid-renderer/edit-configuration/edit-configuration.component';
import { EditUserRoleRenderer } from './core/grid-renderer/edit-user-role-renderer.component';
import { ManageAttributeAction } from './core/grid-renderer/manage-attribute-action/manage-attribute-action.component';
import { RefreshComponent } from './core/grid-renderer/refresh/refresh.component';
import { TrialEventsAction } from './core/grid-renderer/trial-events-action/trial-events-action.component';
import { ViewEventComponent } from './core/grid-renderer/view-event/view-event.component';
import { UserBoxRenderer } from './core/grid-renderer/userboxrenderer.component';
import { GridComponent } from './core/grid/grid.component';
import { NotificationMessageComponent } from './core/notification-message/notification-message.component';
import { ToolbarComponent } from './core/toolbar/toolbar.component';
import { EventsAndOrdersComponent } from './pages/events-and-orders/events-and-orders.component';
import { CreateEventComponent } from './pages/events-and-orders/manage-trial-events/create-event/create-event.component';
import { ManageTrialEventsComponent } from './pages/events-and-orders/manage-trial-events/manage-trial-events.component';
import { HomeComponent } from './pages/home/home.component';
import { NoAccessComponent } from './pages/no-access/no-access.component';
import { RoleSelectorComponent } from './pages/role-selector/role-selector.component';
import { AddOnlineTrainingComponent } from './pages/tmna-namc/manage-online-training-namc-documents/add-online-training/add-online-training.component';
import { DeleteDocumentComponent } from './pages/tmna-namc/manage-online-training-namc-documents/delete-document/delete-document.component';
import { ManageOnlineTrainingComponent } from './pages/tmna-namc/manage-online-training-namc-documents/manage-online-training/manage-online-training.component';
import { ReorderOnlineTrainingComponent } from './pages/tmna-namc/manage-online-training-namc-documents/reorder-online-training/reorder-online-training.component';
import { AddAttributeComponent } from './pages/tmna-namc/manage-attributes/add-attribute/add-attribute.component';
import { ManageAttributesComponent } from './pages/tmna-namc/manage-attributes/manage-attributes.component';
import { ModifyAttributeComponent } from './pages/tmna-namc/manage-attributes/modify-attribute/modify-attribute.component';
import { ManageConfigurationsComponent } from './pages/tmna-namc/manage-configurations/manage-configurations.component';
import { ModifyConfigurationComponent } from './pages/tmna-namc/manage-configurations/modify-configuration/modify-configuration.component';
import { AddEditUserComponent } from './pages/tmna-namc/manage-users/add-edit-user/add-edit-user.component';
import { ManageUsersComponent } from './pages/tmna-namc/manage-users/manage-users.component';
import { TmnaNamcComponent } from './pages/tmna-namc/tmna-namc.component';
import { DisplayHistoryComponent } from './pages/events-and-orders/trial-orders/display-history/display-history.component';
import { TrialOrdersComponent } from './pages/events-and-orders/trial-orders/trial-orders.component';
import { DisplayOrderShippingAddressComponent } from './pages/events-and-orders/trial-orders/display-order-shipping-address/display-order-shipping-address.component';
import { PerformanceComponent } from './pages/supplier/performance/performance.component';
import { LateOrderGridComponent } from './pages/supplier/performance/late-order-grid/late-order-grid.component';
import { ContactsComponent } from './pages/supplier/contacts/contacts.component';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { DisableSpecialCharactersDirective } from './core/common/disable-special-characters';
import { WarningPopupComponent } from './core/dialogs/warning-popup/warning-popup.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    BreadcrumbComponent,
    ToolbarComponent,
    NotificationMessageComponent,
    RoleSelectorComponent,
    HomeComponent,
    EventsAndOrdersComponent,
    ManageTrialEventsComponent,
    NoAccessComponent,
    ContactsComponent,
    ManageOnlineTrainingComponent,
    AddOnlineTrainingComponent,
    ReorderOnlineTrainingComponent,
    DeleteDocumentComponent,
    TmnaNamcComponent,
    ManageAttributesComponent,
    ModifyAttributeComponent,
    AddAttributeComponent,
    GridComponent,
    RefreshComponent,
    ManageConfigurationsComponent,
    ModifyConfigurationComponent,
    CreateEventComponent,
    TrialEventsAction,
    ViewEventComponent,
    DeleteEventComponent,
    ManageAttributeAction,
    EditConfigurationComponent,
    DeleteAttributeComponent,
    // BaseComponent,
    // RoutingtestComponent,
    UserBoxRenderer,
    EditUserRoleRenderer,
    TmnaNamcComponent,
    ManageUsersComponent,
    AddEditUserComponent,
    DisplayHistoryComponent,
    TrialOrdersComponent,
    DisplayOrderShippingAddressComponent,
    PerformanceComponent,
    LateOrderGridComponent,
    RefreshComponent,
    GridComponent,
    UserBoxRenderer,
    EditUserRoleRenderer,
    DisableSpecialCharactersDirective,
    WarningPopupComponent
  ],

  imports: [
    MultiSelectModule,
    DropdownModule,
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    AgGridModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatMenuModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatExpansionModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    MatSelectModule,
    NgxMaterialTimepickerModule,
    MatSlideToggleModule,
    NgChartsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    MatRadioModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    // Constants,
    DatePipe,
    // ConstantsService
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
