import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, from, switchMap, throwError } from 'rxjs';
import { LoginService } from './login.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private readonly loginService: LoginService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isRefresh = req.url.includes('/auth/refresh');
    const isUser = req.url.endsWith('/user')
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if ((error.status === 401 && !isRefresh) || (isUser && error.status === 403)) {
          // Attempt to refresh token
          return from(this.loginService.refreshToken()).pipe(
            switchMap(() => {
              // Retry the failed request with the new token
              return next.handle(req);
            }),
            catchError((refreshError) => {
              // If refresh also fails, redirect to login
              this.loginService.login();
              return throwError(() => refreshError);
            })
          );
        }
        return throwError(() => error);
      })
    );
  }
}
