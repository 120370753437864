<div class="popup-container">
  <button class="close black-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>

  <h2 class="p-3 mb-0 roboto-medium">Delete Event</h2>
  <hr id="shadow" />
  <br />
  <app-notification-message
    [errorMessage]="errorMessage"
    [successMessage]="successMessage"
  >
  </app-notification-message>
  <div>
    <p class="roboto-bold paragraph">
      Are you sure you want to delete the event and cancel all it's orders?
    </p>
  </div>
  <div class="right-align mt-3">
    <button (click)="closeDialog()" class="cancel me-3 ms-3 roboto-bold">
      CANCEL
    </button>
    <button (click)="deleteEvent()" class="deletebutt roboto-medium">
      DELETE
    </button>
  </div>
</div>
