import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CellClassParams, ColDef } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DeleteEventComponent } from 'src/app/core/dialogs/delete-event/delete-event.component';
import { RefreshComponent } from 'src/app/core/grid-renderer/refresh/refresh.component';
import { TrialEventsAction } from 'src/app/core/grid-renderer/trial-events-action/trial-events-action.component';
import { ViewEventComponent } from 'src/app/core/grid-renderer/view-event/view-event.component';
import { TrialEventsList } from 'src/app/core/model/manage-trial-events.model';
import { User } from 'src/app/core/model/user.model';
import { ManageTrialEventsService } from 'src/app/services/events-and-orders/manage-trial-events.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-manage-trial-events',
  templateUrl: './manage-trial-events.component.html',
  styleUrls: ['./manage-trial-events.component.scss'],
})
export class ManageTrialEventsComponent implements OnInit {
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  checkEditable = false;
  columnDefs: ColDef[] = [];
  displayRowData: TrialEventsList[] = [];
  context: { componentParent: ManageTrialEventsComponent };
  namcCode = '';
  currentRole = '';
  user: User | null = null;
  createEventBtnCheck = false;

  constructor(
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly manageTrialEventService: ManageTrialEventsService,
    private readonly userService: UserService,
    private readonly datePipe: DatePipe
  ) {
    // No Work Here
    this.context = {
      componentParent: this,
    };
  }

  ngOnInit(): void {
    const message = this.manageTrialEventService.getMessage();
    if (message) {
      this.successMessage = message;
      this.settingTimeOut();
      this.manageTrialEventService.setMessage();
    }
    this.loadTrialEventsGrid();
    this.userService.userDetails.subscribe((user) => {
      this.user = user;
      if (user) {
        this.loadRoleDetails();
      }
    });
  }

  loadRoleDetails() {
    this.userService.selectedRoles.subscribe((selectedRoles) => {
      const role = this.user?.roles.find(
        (value) => value.namcCode === selectedRoles?.namc
      );
      this.currentRole = role?.roleDescription ?? '';
      this.namcCode = role?.namcCode ?? '';
    });
    this.createEventBtnCheck =
      this.currentRole === 'TMNA Admin' || this.currentRole === 'NAMC Admin';
    this.getColDefs();
  }
  getColDefs() {
    this.columnDefs = [
      {
        headerName: 'EVENT STATUS',
        field: 'eventStatus',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 120,
        maxWidth: 120,
        cellStyle: cellStyle,
      },
      {
        headerName: 'EVENT NAME',
        field: 'eventName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        cellRenderer: ViewEventComponent,
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 270,
        maxWidth: 270,
      },
      {
        headerName: 'DESCRIPTION',
        field: 'eventDescription',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 370,
        maxWidth: 370,
      },
      {
        headerName: 'PROJECT CODE',
        field: 'projectCode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 110,
        maxWidth: 110,
      },
      {
        headerName: 'NAMC CODE',
        field: 'namcCode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 110,
        maxWidth: 110,
      },
      {
        headerName: 'PART SOURCE',
        field: 'partSource',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 110,
        maxWidth: 110,
      },
      {
        headerName: 'DOCK CODE',
        field: 'dockCode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 110,
        maxWidth: 110,
      },
      {
        headerName: 'LOGISTIC PROVIDER',
        field: 'logisticProvider',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 180,
        maxWidth: 180,
      },
      {
        headerName: 'COMPLETION DATE',
        field: 'completionDate',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 140,
        maxWidth: 140,
      },
      {
        headerName: 'COST CENTER',
        field: 'costCenter',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 120,
        maxWidth: 120,
        hide: this.namcCode === '71NPE',
      },

      {
        headerName: 'RINGI NUMBER',
        field: 'ringiNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains', 'notContains'], // Specify filter options
          textFormatter: (value: string) => value.trim().toLowerCase(), // Trim and format text
          filter: (filterValue: string, cellValue: string) => {
            const trimmedFilterValue = filterValue.trim().toLowerCase();
            return cellValue.trim().toLowerCase().includes(trimmedFilterValue);
          },
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 170,
        maxWidth: 170,
        hide: this.namcCode !== '71NPE',
      },
      {
        headerName: 'ACTION',
        floatingFilterComponent: RefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellRenderer: TrialEventsAction,
        minWidth: 110,
        maxWidth: 110,
      },
    ];
  }

  loadTrialEventsGrid() {
    this.ngxUiLoaderService.start('trialEventsListLoad');
    this.manageTrialEventService.getTrialEventsList().subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('trialEventsListLoad');
        this.displayRowData = [];
        this.displayRowData = result;
        this.displayRowData = this.displayRowData.map(
          (item: TrialEventsList) => {
            return {
              ...item,
              eventStatus: item.eventStatus.toUpperCase(),
              completionDate: item.completionDate
                ? this.datePipe.transform(item.completionDate, 'MM/dd/yyyy')
                : item.completionDate,
              actionBtnCheck: this.createEventBtnCheck,
              userId: this.user?.userId,
            };
          }
        );
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('trialEventsListLoad');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error;
        this.settingTimeOut();
      },
    });
  }

  createEvent() {
    this.router.navigate(['/event-order/manage-trial-events/create-new-event']);
  }

  deleteEvent(eventId: number, updatedBy: string | undefined) {
    const dialogRef = this.dialog.open(DeleteEventComponent, {
      disableClose: true,
      width: '570px',
      height: '185px',
      data: {
        eventId,
        updatedBy,
      },
    });

    dialogRef.afterClosed()?.subscribe((result: string | undefined) => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
      if (result === undefined) {
        return;
      }
      if (result === 'Success') {
        this.successMessage = 'Event Deleted Successfully';
        setTimeout(() => {
          this.loadTrialEventsGrid();
        }, 0);
        this.settingTimeOut();
      }
    });
  }
  settingTimeOut() {
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 5000);
  }
}
export function cellStyle(params: CellClassParams) {
  if (params.data.eventStatus.toUpperCase() === 'COMPLETED') {
    return cellStyleCompleted();
  } else if (params.data.eventStatus.toUpperCase() === 'ACTIVE') {
    return cellStyleActive();
  } else if (params.data.eventStatus.toUpperCase() === 'UNKNOWN') {
    return cellStyleUnknown();
  } else {
    return cellStyleDeleted();
  }
}

export function cellStyleActive() {
  return {
    display: 'flex',
    'justify-content': 'center',
    'background-color': '#2688DA',
    color: '#FFFFFF',
    'border-radius': '25px',
    margin: '0 10px',
    'max-width': '90px',
    height: '20px',
  };
}

export function cellStyleDeleted() {
  return {
    display: 'flex',
    'justify-content': 'center',
    'background-color': '#ff4747',
    color: '#FFFFFF',
    'border-radius': '25px',
    margin: '0 10px',
    'max-width': '90px',
    height: '20px',
  };
}

export function cellStyleUnknown() {
  return {
    display: 'flex',
    'justify-content': 'center',
    'background-color': '#eae6b5',
    color: 'black',
    'border-radius': '25px',
    margin: '0 10px',
    'max-width': '90px',
    height: '20px',
  };
}

export function cellStyleCompleted() {
  return {
    display: 'flex',
    'justify-content': 'center',
    'background-color': '#25AA00',
    color: '#FFFFFF',
    'border-radius': '25px',
    margin: '0 10px',
    'max-width': '90px',
    height: '20px',
  };
}
