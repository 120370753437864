<app-breadcrumb></app-breadcrumb>
<div class="manageUser-btn">
  <button
    class="mt-2 me-3 black-button"
    (click)="routeAddUser()"
    style="float: right"
  >
    ADD USER
  </button>
</div>
<app-grid
  [columnDefs]="columnDefs"
  [displayRowData]="displayRowData"
></app-grid>
