import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss']
})
export class WarningPopupComponent implements OnInit {
  faWarning = faTriangleExclamation;
  constructor( public dialogRef: MatDialogRef<WarningPopupComponent>) { }

  ngOnInit(): void {
    //No work
  }

  closeDialog() {
    this.dialogRef.close();
  }

  continueWithoutSaving(){
    this.dialogRef.close('Success');
  }

}
