<div class="popup-container">
  <button class="close black-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>

  <h2 class="p-3 mb-0 roboto-medium">
    <fa-icon class="me-1" [icon]="faWarning"></fa-icon>
    Warning
  </h2>
  <hr id="shadow" />
  <br />
  <div>
    <p class="roboto-bold paragraph">Are you certain you want to leave without saving?</p>
    <p class="roboto-bold paragraph">Click "Cancel" to go back and save.</p>
  </div>
  <div class="right-align mt-3">
    <button
      (click)="continueWithoutSaving()"
      class="black-button roboto-medium"
    >
      CONTINUE WITHOUT SAVING
    </button>
    <button (click)="closeDialog()" class="cancel me-3 ms-3 roboto-bold">
      CANCEL
    </button>
  </div>
</div>
