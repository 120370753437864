import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
})
export class NotificationMessageComponent implements OnInit {
  @Input()
  successMessage: string | undefined = undefined;
  @Input()
  errorMessage: string | undefined = undefined;

  constructor() {
    // No Work Here
  }

  ngOnInit(): void {
    // No Work Here
  }

  close() {
    this.errorMessage = undefined;
    this.successMessage = undefined;
  }
}
